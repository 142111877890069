import React, { PropsWithChildren, useContext, useState } from 'react';

type PopupContextType = {
  replaceContent: (content: React.ReactNode) => void;
};

const contextValue: PopupContextType = {
  replaceContent: () => {},
};

export const Context = React.createContext(contextValue);

function PopupContext({ children }: PropsWithChildren<{}>) {
  const [content, setContent] = useState<React.ReactNode>(null);
  const replaceContent = (newContent: React.ReactNode) => setContent(newContent);

  return <Context.Provider value={{ replaceContent }}>{content || children}</Context.Provider>;
}

export const usePopupContext = () => useContext(Context);

export default PopupContext;
