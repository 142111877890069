const rootUrl = 'https://columns-me.s3.us-east-2.amazonaws.com/static';

export const contextUrl = {
  ABOUT: 'about',
  META: 'meta',
  VIDEO: 'video',
} as const;

export type staticContext = typeof contextUrl[keyof typeof contextUrl];

export const staticUrl = (fileName: string, context: staticContext): string =>
  `${rootUrl}/${context}/${fileName}`;
