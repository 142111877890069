import React from 'react';
import { Formik, FormikErrors } from 'formik';

import { removeItem } from 'helpers/array';

import StatelessTagsInput, { TagType } from './StatelessTagsInput';

interface Props {
  inputProps: any;
  prefix?: string;
  onTagAdd: (tag: TagType) => void;
  onTagRemove: (tag: TagType) => void;
  renderSubmitButton?: (
    onClick: (e: React.KeyboardEvent | React.MouseEvent) => void,
    params: {
      values: {
        value: string;
        tags: TagType[];
      };
      errors: FormikErrors<{
        value: boolean;
        tags: never[];
      }>;
      isValid: boolean;
    },
  ) => React.ReactNode;
  onSubmit: (tags: TagType[]) => Promise<any>;
}

const TagsInput: React.FC<Props> = ({
  inputProps: { input, ...inputProps } = {},
  prefix,
  onTagAdd = () => {},
  onTagRemove = () => {},
  onSubmit,
  renderSubmitButton,
}) => (
  <Formik
    initialValues={{
      value: '',
      tags: [],
    }}
    validate={values => {
      const { validate } = inputProps;

      const errors: { value?: boolean; tags?: boolean } = {};
      const isInputValid = validate ? validate(values.value, values.tags) : true;

      if (values.value && !isInputValid) {
        errors.value = true;
      }

      if (values.tags.length === 0) {
        errors.tags = true;
      }

      return errors;
    }}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      onSubmit(values.tags).then(() => {
        resetForm();
        setSubmitting(false);
      });
    }}
  >
    {({ values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting, isValid }) => {
      const onSafeSubmit = (e: React.KeyboardEvent | React.MouseEvent) => {
        if (!isSubmitting) {
          // @ts-ignore FIXME
          handleSubmit(e);
        }
      };

      const onAdd = (value: string) => {
        const tag = {
          id: values.tags.length,
          label: value,
        };
        const tags = [...values.tags, tag];

        setFieldValue('tags', tags);
        setFieldValue('value', '');

        onTagAdd(tag);
      };

      // @ts-ignore FIXME
      const onButtonClick = async e => {
        const { validate } = inputProps;
        const isInputValid = validate ? validate(values.value, values.tags) : true;

        if (isInputValid) {
          await onAdd(values.value);

          return onSafeSubmit(e);
        }

        if (!errors.tags) {
          return onSafeSubmit(e);
        }
        return undefined;
      };

      return (
        <>
          <StatelessTagsInput
            {...inputProps}
            prefix={prefix}
            tags={values.tags}
            inputProps={{
              ...(input || {}),
              name: 'value',
              value: values.value,
              onChange: handleChange,
            }}
            onAdd={onAdd}
            onRemove={(_, index) => {
              const tags = removeItem(values.tags, index);

              setFieldValue('tags', tags);
              onTagRemove(values.tags[index]);
            }}
            onSubmit={onSafeSubmit}
          />

          {renderSubmitButton && renderSubmitButton(onButtonClick, { values, errors, isValid })}
        </>
      );
    }}
  </Formik>
);

export default TagsInput;
