import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ReduxState } from 'modules';
import { Themes } from 'styles/themes';

import { systemColorSchemeSupported } from 'helpers/dom';
import * as ActionTypes from './constants';

export const setTheme = (theme: Themes): ThunkAction<void, ReduxState, unknown, AnyAction> => (
  dispatch,
  getState,
) => {
  const {
    theme: { currentTheme },
  } = getState();

  if (currentTheme === theme) {
    return null;
  }

  return dispatch({
    type: ActionTypes.SET_THEME,
    theme,
  });
};

export const toggleTheme = (): ThunkAction<void, ReduxState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const {
      theme: { currentTheme },
    } = getState();

    return dispatch({
      type: ActionTypes.SET_THEME,
      theme: currentTheme === 'light' ? 'dark' : 'light',
    });
  };
};

export const toggleSystemSync = (): ThunkAction<void, ReduxState, unknown, AnyAction> => {
  return dispatch => {
    if (!systemColorSchemeSupported()) {
      return null;
    }

    const theme: Themes = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';

    dispatch({
      type: ActionTypes.TOGGLE_SYSTEM_SYNC,
    });
    dispatch(setTheme(theme));
  };
};
