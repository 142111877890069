import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

import { colors } from 'styles/theme';
import { mediaSelector } from 'helpers/style-utils';

import Loader from 'components/shared/Loader/Loader';
import FileInput from 'components/shared/FileInput/FileInput';
import PlusIcon from 'vector/16/PlusIcon.svg';

const Handler = styled.div<{ isUploading?: boolean }>`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.currentTheme.disabled};
  border-radius: 50%;
  opacity: ${p => (p.isUploading ? '1' : '0')};

  #plus-icon {
    path {
      fill: ${p => p.theme.colors.white};
    }
  }
`;

const Container = styled.div`
  position: relative;

  ${mediaSelector.hoverSupported} {
    &:hover ${Handler} {
      opacity: 1;
    }
  }
`;

interface Props {
  id: string;
  children: React.ReactNode;
  mutation: any;
  onClick?: () => void;
  onSuccess?: () => void;
}

const UploadAvatarForm: React.FC<Props> = ({ id, mutation, children, onClick, onSuccess }) => {
  const [updateAvatar, { loading }] = useMutation<any, any>(mutation);

  return (
    <Container>
      <FileInput
        id="avatar"
        disabled={loading}
        accept="image/*"
        onClick={onClick}
        onFilesAdd={([profileImage]) =>
          updateAvatar({
            variables: {
              input: {
                id,
                profileImage,
              },
            },
          }).then(onSuccess)
        }
      >
        <Handler isUploading={loading}>
          {loading ? (
            <Loader size={30} color={colors.white} />
          ) : (
            <PlusIcon width={25} height={25} />
          )}
        </Handler>
        {children}
      </FileInput>
    </Container>
  );
};

export default UploadAvatarForm;
