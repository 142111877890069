import React from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from 'styles/theme';

import LoaderIcon from './LoaderIcon.svg';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIcon = styled(({ color, ...props }) => <LoaderIcon {...props} />)<{ color: string }>`
  width: 100%;
  height: 100%;
  animation: ${rotate360} 0.8s linear infinite;

  path {
    stroke: ${props => props.color};
  }
`;

interface Props {
  size?: number;
  color?: string;
}

const Loader: React.FC<Props> = ({ size = 15, color = colors.dodgerBlue }) => (
  <LoaderContainer>
    <StyledIcon style={{ width: `${size}px`, height: `${size}px` }} color={color} />
  </LoaderContainer>
);

export default Loader;
