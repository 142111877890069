import React from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const Background = styled.div`
  display: block;
  background: ${p => p.theme.currentTheme.mainSidebarBg};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.98;

  ${mediaSelector.mobile} {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  height: 100%;
`;

const VerticalPanel: React.FC<{}> = ({ children }) => (
  <Container>
    <Background />
    <Wrapper>{children}</Wrapper>
  </Container>
);

export default VerticalPanel;
