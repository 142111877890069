import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'lib/i18n';

import { mediaSelector } from 'helpers/style-utils';

import Link from 'components/shared/Link/Link';

// prettier-ignore
const ResendButton = styled(Link)<{ isDisabled: boolean }>`
  text-align: center;

  ${props => props.isDisabled && `
    color: ${props.theme.colors.silver};
    cursor: default;

    ${mediaSelector.hoverSupported} {
      &&:hover {
        text-decoration: none;
      }
    }
  `}
`;

const RESEND_TIMEOUT = 1000 * 30;

const getFormattedResendTimeout = (resendTime: number) => {
  const minutes = Math.floor(resendTime / 1000 / 60);
  const seconds = Math.floor((resendTime / 1000) % 60); // eslint-disable-line no-mixed-operators

  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

type Props = {
  resendConfirmationEmail: () => Promise<any>;
};

const ConfirmEmailInput: React.FC<Props> = ({ resendConfirmationEmail }) => {
  const [resendTimeout, setResendTimeout] = useState(0);
  const intervalId = useRef<number | undefined>(undefined);
  const { t } = useTranslation('registration');
  const canResend = resendTimeout === 0;

  useEffect(() => {
    if (resendTimeout === RESEND_TIMEOUT) {
      intervalId.current = setInterval(() => {
        setResendTimeout(value => {
          const nextValue = value - 1000;
          return nextValue < 0 ? 0 : nextValue;
        });
      }, 1000);
    }

    if (resendTimeout === 0) {
      clearInterval(intervalId.current);
    }
  }, [resendTimeout]);

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  const resendCode = () => {
    if (!canResend) {
      return;
    }

    resendConfirmationEmail().finally(() => setResendTimeout(RESEND_TIMEOUT));
  };

  return (
    <ResendButton
      as="div"
      onClick={() => resendCode()}
      // @ts-ignore FIXME
      isDisabled={!canResend}
    >
      {t('confirm-email.resend')}{' '}
      {!canResend && <strong>{getFormattedResendTimeout(resendTimeout)}</strong>}
    </ResendButton>
  );
};

export default ConfirmEmailInput;
