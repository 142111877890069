import { PlaceholderType } from 'components/boards/dnd/types';
import reducer from '../reducerCreator';
import * as ActionTypes from './constants';

export type State = PlaceholderType | null;

const initialState: State = null;

const handlers = {
  [ActionTypes.SET_PLACEHOLDER]: ({ placeholder }: { placeholder: State }) => placeholder,
};

export default reducer(handlers, initialState);
