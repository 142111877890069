import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { replaceAt } from 'helpers/string';
import { mediaSelector } from 'helpers/style-utils';

import Input, { InputError } from 'components/Input/Input';

const Container = styled.div``;

const InputContainer = styled.div``;

const CodeMultiInputContainer = styled.div`
  margin-bottom: 20px;
  text-align: center;
  display: flex;
`;

const CodeInputContainer = styled.div`
  margin: 0px 4px;

  &:nth-child(3) {
    margin-left: 22px;
  }

  &:nth-child(5) {
    margin-left: 22px;
  }

  ${mediaSelector.mobile} {
    &:nth-child(3) {
      margin-left: 15px;
    }

    &:nth-child(5) {
      margin-left: 15px;
    }
  }
`;

const CodeInput = styled(Input)<{ isSubmitting: boolean }>`
  font-size: 3.6rem;
  text-align: center;
  width: 45px;
  height: 60px;
  padding: 0;
  // @ts-ignore FIXME
  opacity: ${props => (props.isSubmitting ? '.2' : '1')};

  ${mediaSelector.mobile} {
    width: 100%;
    height: 60px;
  }
`;

const Error = styled(InputError)`
  text-align: center;
`;

const getValue = (value: string) => value.replace(/\D/g, '');

type Props = {
  code: string;
  error?: string;
  isSubmitting: boolean;
  onChange: (code: string, shouldValidate?: boolean) => void;
  onSubmit: () => void;
};

type Inputs = {
  [key: number]: HTMLInputElement | null;
};

const ConfirmEmailInput: React.FC<Props> = ({ code, error, onChange, onSubmit, isSubmitting }) => {
  const inputs = useRef<Inputs>({});

  const getInputByIndex = (index: number) => {
    if (inputs.current) {
      return inputs.current[index];
    }
  };

  useEffect(() => {
    const input = getInputByIndex(0);

    if (input) {
      input.focus();
    }
  }, []);

  const setInput = (index: number) => (input: HTMLInputElement | null) => {
    inputs.current[index] = input;
  };

  const focusInput = (index: number) => {
    const input = getInputByIndex(index);

    if (input) {
      setTimeout(() => {
        input.focus();
        input.selectionStart = input.selectionEnd = 10000; // eslint-disable-line no-multi-assign
        input.select();
      }, 0);
    }
  };

  const onInputChange = (index: number) => ({ target }: React.FormEvent<HTMLInputElement>) => {
    if (isSubmitting) {
      return;
    }

    const value = getValue((target as HTMLInputElement).value);

    if (!value) {
      return;
    }

    if (value.length > 1) {
      return focusInput(index + 1); // eslint-disable-line consistent-return
    }

    const newValue = replaceAt(code, index, value);

    onChange(newValue, false);

    if (newValue.length === 6) {
      return onSubmit(); // eslint-disable-line consistent-return
    }

    if (index + 1 < newValue.length) {
      return focusInput(index + 1); // eslint-disable-line consistent-return
    }

    if (newValue.length >= code.length) {
      focusInput(newValue.length);
    }
  };

  const onKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isSubmitting) {
      return;
    }

    if (e.key === 'Backspace') {
      const replaceIndex = code.length === index ? index - 1 : index;

      onChange(replaceAt(code, replaceIndex, ''));
      return focusInput(index - 1); // eslint-disable-line consistent-return
    }
  };

  const onPaste = (index: number) => (e: React.ClipboardEvent) => {
    if (isSubmitting) {
      return;
    }

    e.preventDefault();

    const value = getValue(e.clipboardData.getData('Text'));

    if (value) {
      const newValue = `${code.slice(0, index)}${value}`.slice(0, 6);

      onChange(newValue, false);
      focusInput(newValue.length === 6 ? 5 : newValue.length);

      if (newValue.length === 6) {
        onSubmit();
      }
    }
  };

  return (
    <Container>
      <CodeMultiInputContainer>
        {[...Array(6)].map((
          _,
          index, // eslint-disable-line react/no-array-index-key
        ) => (
          // eslint-disable-next-line react/no-array-index-key
          <CodeInputContainer key={`code-input-${index}`}>
            <CodeInput
              id="code"
              type="tel"
              autoComplete="off"
              value={code.charAt(index)}
              onChange={onInputChange(index)}
              onPaste={onPaste(index)}
              onClick={() => focusInput(index)}
              onKeyDown={onKeyDown(index)}
              ref={setInput(index)}
              readOnly={isSubmitting}
              // @ts-ignore FIXME
              isSubmitting={isSubmitting}
            />
          </CodeInputContainer>
        ))}
      </CodeMultiInputContainer>
      {error && (
        <InputContainer>
          <Error>{error}</Error>
        </InputContainer>
      )}
    </Container>
  );
};

export default ConfirmEmailInput;
