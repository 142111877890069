import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import GlobalStyles from 'pages/_styles';
import useTheme from 'modules/theme/useTheme';

import theme from './theme';
import themes from './themes';

const ThemeProvider: React.FC<{}> = ({ children }) => {
  const { currentTheme } = useTheme();

  return (
    <StyledThemeProvider
      theme={{ ...theme, currentTheme: themes[currentTheme], themeName: currentTheme }}
    >
      <GlobalStyles />
      {children}
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
