import { Themes } from 'styles/themes';
import reducer from '../reducerCreator';
import * as ActionTypes from './constants';

export type State = {
  currentTheme: Themes;
  systemThemeSync: boolean;
};

const initialState: State = {
  currentTheme: 'light',
  systemThemeSync: true,
};

const handlers = {
  [ActionTypes.SET_THEME]: (action: { theme: Themes }, state: State): State => ({
    ...state,
    currentTheme: action.theme,
  }),
  [ActionTypes.TOGGLE_SYSTEM_SYNC]: (_: any, state: State): State => ({
    ...state,
    systemThemeSync: !state.systemThemeSync,
  }),
};

export default reducer(handlers, initialState);
