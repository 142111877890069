import React from 'react';
import { useTranslation } from 'lib/i18n';

import ListItem from 'components/shared/Dropdown/ListItem';
import CopyToClipboard from 'components/shared/CopyToClipboard/CopyToClipboard';

import CopyLinkIcon from 'client/components/v2/shared/svg/24//CopyLinkIcon.svg';

interface Props {
  value: string;
  text?: string;
  note?: string;
  successText?: string;
  icon?: React.ReactNode;
  onCopy?: () => void;
}

const CopyLinkListItem: React.FC<Props> = ({ value, text, note, successText, icon, onCopy }) => {
  const { t } = useTranslation('common');

  return (
    <CopyToClipboard {...{ value, onCopy }}>
      {({ isCopied, handleCopy }) => (
        <ListItem
          note={note}
          icon={icon || <CopyLinkIcon />}
          mobileIcon={icon}
          onClick={() => handleCopy()}
        >
          {isCopied ? successText || t('copied') : text || t('copyLink')}
        </ListItem>
      )}
    </CopyToClipboard>
  );
};

export default CopyLinkListItem;
