/**
 * Inserts item into array at specified index
 */
export const insertItem = <T>(arr: T[], item: any, index?: number): T[] => {
  if (typeof index === 'undefined') {
    return [...arr, item];
  }

  return [...arr.slice(0, index), item, ...arr.slice(index)];
};

/**
 * Inserts items into array at specified index
 */
export const insertItems = <T>(arr: T[], items: T[], index?: number): T[] => {
  if (typeof index === 'undefined') {
    return [...arr, ...items];
  }

  return [...arr.slice(0, index), ...items, ...arr.slice(index)];
};

/**
 * Remove item from array at specified index
 */
export const removeItem = <T>(arr: T[], index: number): T[] => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
];

/**
 * Swaps two elements in array
 */
export const swapElements = <T>(arr: T[], source: number, destination: number): T[] =>
  insertItem(removeItem(arr, source), arr[source], destination);

export const unique = <T>(arrArg: T[]): T[] =>
  arrArg.filter((elem, pos, arr) => arr.indexOf(elem) === pos);

/**
 * Reverse array
 * Native Array.reverse is slower
 */
export const reverse = <T>(arr: T[]): T[] => arr.map((_, i, a) => a[a.length - 1 - i]);
