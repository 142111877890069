import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as actionCreators from 'modules/queue/actions';

import { ReduxState } from 'modules';

export interface QueueProps {
  isQueued: boolean;
  isQueuedLast: boolean;
  pushToQueue: () => void;
  popFromQueue: () => void;
  removeFromQueue: () => void;
}

export const useQueue = (initKey?: string) => {
  const [itemId, setItemId] = useState<number | null>();
  const dispatch = useDispatch();
  const queue = useSelector<ReduxState, ReduxState['queue']>(state => state.queue);
  const lastQueueItem = queue[queue.length - 1];
  const isQueuedLast = !!(itemId && itemId === lastQueueItem?.id);
  const isQueued = !!queue.find(item => item.id === itemId);

  useEffect(() => {
    if (!isQueued) {
      setItemId(null);
    }
  }, [isQueued]);

  return {
    isQueued,
    isQueuedLast,
    pushToQueue: (key?: string) => {
      if (!itemId) {
        const id = queue.length + 1;

        setItemId(id);
        dispatch(actionCreators.push(id, key || initKey || 'item'));
      }
    },
    popFromQueue: () => dispatch(actionCreators.pop()),
    removeFromQueue: () => {
      if (itemId) {
        dispatch(actionCreators.remove(itemId));
      }
    },
  };
};

type Props = {
  children: (renderProps: QueueProps) => JSX.Element;
};

export const WithQueue: React.FC<Props> = ({ children }) => {
  const withQueueRenderProps = useQueue();

  return children(withQueueRenderProps);
};

/** @deprecated Use `useQueue` or `WithQueue` instead */
export const withQueue = <P extends QueueProps>(ComposedComponent: React.ComponentType<P>) => {
  const WithQueueContainer = (props: Omit<P, keyof QueueProps>) => (
    <WithQueue>
      {(queueProps: QueueProps) => {
        const p = { ...props, ...queueProps } as P;
        return <ComposedComponent {...p} />;
      }}
    </WithQueue>
  );

  return WithQueueContainer;
};
