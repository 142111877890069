import { trim } from 'helpers/string';

export const paramsToQueryString = (params: { [key: string]: string }) =>
  Object.keys(params).reduce((str, key, i) => {
    if (!params[key]) {
      return str;
    }

    return `${str}${i === 0 ? '' : '&'}${key}=${params[key]}`;
  }, '');

export const queryStringToParams = (asPath = ''): { [key: string]: string } => {
  const q = asPath.split('?')[1];

  if (q) {
    return q.split('&').reduce((params, pair) => {
      const [key, value] = pair.split('=');

      return {
        ...params,
        [key]: value,
      };
    }, {});
  }

  return {};
};

export const excludeQueryStringParam = (asPath = '', param: string) => {
  const { [param]: removedParam, ...params } = queryStringToParams(asPath);
  return paramsToQueryString(params);
};

export const getSearchQueryValue = (q = ''): string => {
  if (q) {
    return trim(decodeURIComponent(q));
  }

  return q;
};

export const cutUrl = (url: string): string => {
  const urlSubpathLength = 10;
  const shortUrl = url
    .replace(/^(?!mailto:)(?:(?:http|https|ftp|ftps):\/\/)/, '')
    .replace(/^www./, '')
    .replace(/\/$/, '');
  const domainLength = shortUrl.search(/\//);

  if (domainLength > 0 && domainLength < shortUrl.length - urlSubpathLength - 1) {
    return `${shortUrl.substr(0, domainLength + urlSubpathLength + 1)}...`;
  }
  return shortUrl;
};

export const getIdFromSlug = (slug: string): string => {
  const segments = slug.split('-');
  return segments[segments.length - 1];
};
