import styled from 'styled-components';
import { mediaSelector } from 'helpers/style-utils';

const Link = styled.a<{ isReversed?: boolean }>`
  color: ${p => p.theme.currentTheme.primary};
  text-decoration: ${p => (p.isReversed ? 'underline' : 'none')};
  cursor: pointer;

  ${mediaSelector.hoverSupported} {
    &:hover {
      text-decoration: ${p => (p.isReversed ? 'none' : 'underline')};
    }
  }

  &:focus {
    text-decoration: ${p => (p.isReversed ? 'none' : 'underline')};
  }
`;

export default Link;
