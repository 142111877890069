import React from 'react';
import { useQuery, gql } from '@apollo/client';

import currentUserFragment from './currentUserFragment';

import {
  WithCurrentUserQuery,
  WithCurrentUserQuery_currentUser as CurrentUser,
} from './__generated__/WithCurrentUserQuery';

export const currentUserQuery = gql`
  query WithCurrentUserQuery {
    currentUser {
      ...CurrentUserFragment
    }
  }
  ${currentUserFragment}
`;

export interface WithCurrentUserProps {
  currentUser?: CurrentUser;
}

export interface Props {
  children: (currentUser?: CurrentUser | null | undefined) => JSX.Element | null;
}

const WithCurrentUser: React.FC<Props> = ({ children }) => {
  const { data, loading } = useQuery<WithCurrentUserQuery>(currentUserQuery);

  if (loading) {
    return null;
  }

  return children(data?.currentUser);
};

export const withCurrentUser = <P extends object>(ComposedComponent: React.ComponentType<P>) => (
  props: P,
) => (
  <WithCurrentUser>
    {currentUser => <ComposedComponent currentUser={currentUser} {...props} />}
  </WithCurrentUser>
);

export const getCurrentUser = () => {
  const { data } = useQuery<WithCurrentUserQuery>(currentUserQuery);

  return data?.currentUser || null;
};

export default WithCurrentUser;
