import React from 'react';
import App from 'next/app';
import { getCookie } from 'cookies-next';
import { NextPage } from 'next';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';

import { app, env } from 'config/env';
import checkLoggedIn from 'lib/checkLoggedIn';

import { AppContext } from './types/context';

import initApolloClient, { ApolloClientOptions } from './initApollo';

const initOnContext = (ctx: AppContext, options: ApolloClientOptions): AppContext => {
  const inAppContext = Boolean(ctx.ctx);

  // Initialize ApolloClient if not already done
  const apolloClient = ctx.apolloClient || initApolloClient(ctx.apolloState || {}, options);

  // We send the Apollo Client as a prop to the component to avoid calling initApollo() twice in the server.
  // Otherwise, the component would have to call initApollo() again but this
  // time without the context. Once that happens, the following code will make sure we send
  // the prop as `null` to the browser.
  // @ts-ignore
  apolloClient.toJSON = () => null;

  // Add apolloClient to NextPageContext & NextAppContext.
  // This allows us to consume the apolloClient inside our
  // custom `getInitialProps({ apolloClient })`.
  ctx.apolloClient = apolloClient;

  if (inAppContext) {
    ctx.ctx.apolloClient = apolloClient;
  }

  return ctx;
};

const withApollo = (PageComponent: NextPage, { ssr = true } = {}): React.ReactNode => {
  const WithApollo = ({
    apolloClient,
    apolloState,
    ...pageProps
  }: {
    apolloClient: ApolloClient<NormalizedCacheObject>;
    apolloState: NormalizedCacheObject;
  }) => {
    const client =
      apolloClient ||
      initApolloClient(apolloState, {
        getToken: () => {
          return getCookie(app.tokenCookie);
        },
        getRefreshToken: () => {
          return getCookie(app.refreshTokenCookie);
        },
      });

    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  if (env.development) {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';
    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx: AppContext) => {
      const inAppContext = Boolean(ctx.ctx);
      const { apolloClient } = initOnContext(ctx, {
        getToken: () => {
          return getCookie(app.tokenCookie, { req: ctx.ctx.req });
        },
        getRefreshToken: () => {
          return getCookie(app.refreshTokenCookie, { req: ctx.ctx.req });
        },
        req: ctx.ctx.req,
        res: ctx.ctx.res,
      });
      const { currentUser } = await checkLoggedIn(apolloClient);
      const isLoggedIn = !!currentUser;

      ctx.isLoggedIn = isLoggedIn;

      if (inAppContext) {
        ctx.ctx.isLoggedIn = isLoggedIn;
      }

      let pageProps: any = {};

      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      } else if (inAppContext) {
        pageProps = await App.getInitialProps(ctx);
      }

      if (typeof window === 'undefined') {
        const { AppTree } = ctx;

        if (ctx.res && ctx.res.writableEnded) {
          return pageProps;
        }

        if (ssr && AppTree) {
          try {
            const { getDataFromTree } = await import('@apollo/react-ssr');
            let props;

            if (inAppContext) {
              props = {
                ...pageProps,
                apolloClient,
              };
            } else {
              props = {
                pageProps: {
                  ...pageProps,
                  apolloClient,
                },
              };
            }

            await getDataFromTree(<AppTree {...props} />);
          } catch (error) {
            console.error('Error while running `getDataFromTree`', error); // eslint-disable-line no-console
          }
        }
      }

      return {
        ...pageProps,
        apolloState: apolloClient?.cache.extract(),
        apolloClient: ctx.apolloClient,
      };
    };
  }

  return WithApollo;
};

export default withApollo;
