import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';
import { useLayer, TriggerProps as ReactLaagTriggerProps, UseLayerOptions } from 'react-laag';
import { useMobileViewContext } from 'components/boards/MobileViewContext';
import { ResizeObserver } from '@juggle/resize-observer';

import { Options, ClosedByUnion } from './useDropdownVisibility';
import DropdownContext, { useDropdownContext } from './DropdownContext';
import ContentContext from './ContentContext';
import DesktopContent from './DesktopContent';
import MobileContent from './MobileContent';
import Trigger, { Props as DefaultTriggerProps } from './Trigger/Trigger';

export const TriggerContainer = styled.span``;

export type TriggerProps = {
  triggerProps: ReactLaagTriggerProps & {
    onClick: (e: React.MouseEvent<Element>) => void;
  };
  isOpen: boolean;
};

type ChildrenProps = {
  isOpen: boolean;
  hide: (by?: ClosedByUnion) => void;
};

type Props = {
  layerOptions?: Pick<UseLayerOptions, Exclude<keyof UseLayerOptions, 'isOpen'>>;
  triggerProps?: DefaultTriggerProps;
  trigger?: React.ReactNode | ((triggerProps: TriggerProps) => React.ReactNode);
  desktopContentStyles?: FlattenSimpleInterpolation;
  children: React.ReactNode | ((childrenProps: ChildrenProps) => React.ReactNode);
};

const DropdownBase = ({
  layerOptions,
  trigger,
  triggerProps,
  desktopContentStyles,
  children,
}: Props) => {
  const { isMobileView } = useMobileViewContext();
  const { isOpen, hide, toggle } = useDropdownContext();
  const { triggerProps: layerTriggerProps, renderLayer, layerProps } = useLayer({
    isOpen,
    auto: true,
    possiblePlacements: ['top-center', 'bottom-center'],
    onOutsideClick: () => hide('Click'),
    ResizeObserver,
    ...layerOptions,
  });

  const onTriggerClick = (e: React.MouseEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    toggle('Click');
  };

  return (
    <>
      {typeof trigger === 'function' ? (
        trigger({ isOpen, triggerProps: { ...layerTriggerProps, onClick: onTriggerClick } })
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <TriggerContainer {...layerTriggerProps} onClick={onTriggerClick}>
          {trigger || <Trigger {...triggerProps} isActive={isOpen} />}
        </TriggerContainer>
      )}
      {isOpen &&
        (isMobileView
          ? renderLayer(
              <div ref={layerProps.ref}>
                <MobileContent>
                  <ContentContext>
                    {typeof children === 'function' ? children({ isOpen, hide }) : children}
                  </ContentContext>
                </MobileContent>
              </div>,
            )
          : renderLayer(
              <div {...layerProps}>
                <DesktopContent styles={desktopContentStyles}>
                  <ContentContext>
                    {typeof children === 'function' ? children({ isOpen, hide }) : children}
                  </ContentContext>
                </DesktopContent>
              </div>,
            ))}
    </>
  );
};

const Dropdown: React.FC<Props & Options> = ({ isOpen, onShow, onHide, children, ...props }) => (
  <DropdownContext isOpen={isOpen} onShow={onShow} onHide={onHide}>
    <DropdownBase {...props}>{children}</DropdownBase>
  </DropdownContext>
);

export default Dropdown; // React.memo<React.FC<Props & Options>>(Dropdown);
