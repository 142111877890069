import styled from 'styled-components';

interface Props {
  isActive?: boolean;
}

const Counter = styled.div<Props>`
  font-size: 1rem;
  line-height: 1.3rem;
  text-align: center;
  letter-spacing: -0.001rem;
  height: 15px;
  min-width: 15px;
  display: inline-block;
  border-radius: 8px;
  padding: 0px 3px;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.gullGray : theme.colors.silver)};
  border: 1px solid
    ${({ isActive, theme }) => (isActive ? theme.colors.gullGray : theme.colors.silver)};
`;

export default Counter;
