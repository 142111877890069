import * as ActionTypes from './constants';

export const push = (id: number, key: string) => ({
  type: ActionTypes.PUSH,
  id,
  key,
});

export const pop = () => ({
  type: ActionTypes.POP,
});

export const remove = (id: number) => ({
  type: ActionTypes.REMOVE,
  id,
});

export const clearQueue = () => ({
  type: ActionTypes.CLEAR_QUEUE,
});
