import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  font-size: 14px;
  line-height: 1.24;
  margin-top: 6px;
`;

const Wrapper = styled.div`
  display: inline;
`;

const Shortcut = styled.span`
  background-color: ${p => p.theme.colors.dirtyAlabaster};
  opacity: 0.9;
  border-radius: 5px;
  padding: 2px 5px;
`;

type Props = {
  shortcuts?: string[];
};

const ListItemHelper: React.FC<Props> = ({ shortcuts, children }) => {
  const getShortcuts = (shortcuts?: string[]) => {
    return (
      <>
        {shortcuts &&
          shortcuts.map((shortcut, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Wrapper key={index}>
              {!!index && ' + '}
              <Shortcut>{shortcut}</Shortcut>
            </Wrapper>
          ))}
      </>
    );
  };

  return (
    <Container>
      {getShortcuts(shortcuts)} {children}
    </Container>
  );
};

export default ListItemHelper;
