import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import { gql, useMutation, FetchResult } from '@apollo/client';

import Input from './Input';
import ResendCode from './ResendCode';

import {
  ConfirmUserEmailMutation,
  ConfirmUserEmailMutationVariables,
} from './__generated__/ConfirmUserEmailMutation';
import {
  ResendUserConfirmationEmailMutation,
  ResendUserConfirmationEmailMutationVariables,
} from './__generated__/ResendUserConfirmationEmailMutation';

const InputContainer = styled.div`
  margin-bottom: 5px;
`;

const confirmEmailMutation = gql`
  mutation ConfirmUserEmailMutation($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      user {
        id
        emailConfirmed
        email
      }
    }
  }
`;

const resendConfirmationEmailMutation = gql`
  mutation ResendUserConfirmationEmailMutation($input: ResendConfirmationEmailInput!) {
    resendConfirmationEmail(input: $input) {
      result
    }
  }
`;

type Props = {
  onEmailConfirmSuccess?: (result: FetchResult<ConfirmUserEmailMutation>) => void;
  onResendCodeSuccess?: (result: FetchResult<ResendUserConfirmationEmailMutation>) => void;
  onError?: (result: FetchResult<ConfirmUserEmailMutation>) => void;
};

const Form: React.FC<Props> = ({
  onEmailConfirmSuccess = () => {},
  onResendCodeSuccess,
  onError = () => {},
}) => {
  const [confirmEmail] = useMutation<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>(
    confirmEmailMutation,
  );
  const [resendConfirmationEmail] = useMutation<
    ResendUserConfirmationEmailMutation,
    ResendUserConfirmationEmailMutationVariables
  >(resendConfirmationEmailMutation, {
    variables: {
      input: {},
    },
  });

  const initialValues = {
    code: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        return confirmEmail({
          variables: {
            input: {
              verificationCode: values.code,
            },
          },
        })
          .then(result => {
            const emailConfirmed = result.data?.confirmEmail.user?.emailConfirmed;

            if (!emailConfirmed) {
              setSubmitting(false);
              onError(result);

              return setFieldError('code', 'Verification code is incorrect');
            }

            return onEmailConfirmSuccess(result);
          })
          .catch(() => setSubmitting(false));
      }}
      render={({ values, errors, touched, isSubmitting, setFieldValue, submitForm }) => (
        <>
          <InputContainer>
            <Input
              code={values.code}
              error={touched.code ? errors.code : undefined}
              isSubmitting={isSubmitting}
              onChange={(...args) => setFieldValue('code', ...args)}
              onSubmit={submitForm}
            />
          </InputContainer>
          <ResendCode
            resendConfirmationEmail={() => resendConfirmationEmail().then(onResendCodeSuccess)}
          />
        </>
      )}
    />
  );
};

export default Form;
