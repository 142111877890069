import React from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';

import useIsTapped from 'hooks/useIsTapped';
import CheckedIcon from 'vector/CheckedIcon.svg';

export const Container = styled.div<{ isTapped: boolean; withNote: boolean; withIcon: boolean }>`
  display: flex;
  align-items: center;
  min-height: ${p => (p.withNote ? '44px' : '32px')};
  padding: ${p => (p.withIcon ? '0 6px' : '0 10px')};
  position: relative;
  font-size: 1.6rem;
  border-radius: 6px;

  &[data-disabled='true'] {
    cursor: default;
    opacity: 0.4;
  }

  ${mediaSelector.hoverSupported} {
    &:not([data-disabled='true']):hover {
      background-color: ${p => p.theme.currentTheme.active};
      cursor: pointer;
    }
  }

  ${({ isTapped, theme }) =>
    isTapped &&
    `
    &:not([data-disabled="true"]) {
      background-color: ${theme.currentTheme.active};
    }
  `}

  ${mediaSelector.mobile} {
    font-size: 2rem;
    line-height: 2.1rem;
    min-height: ${p => (p.withNote ? '43px' : '43px')};
    padding: 0 10px;
  }

  > svg:first-of-type {
    margin-right: 10px;

    path[fill],
    circle[fill] {
      fill: ${p => p.theme.currentTheme.text};
    }

    path[stroke],
    circle[stroke],
    rect[stroke] {
      stroke: ${p => p.theme.currentTheme.text};
    }
  }
`;

export const Content = styled.div``;

export const Note = styled.div`
  color: ${p => p.theme.currentTheme.notes};
  font-size: 1.3rem;
  line-height: 1.33rem;
`;

const AdditionalWrapper = styled.div`
  margin-left: auto;
  color: ${p => p.theme.colors.bombay};
  font-size: 1.4rem;

  ${mediaSelector.mobile} {
    font-size: 1.6rem;
  }
`;

const CheckedIconContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 11px;
`;

type Props = {
  icon?: React.ReactNode;
  mobileIcon?: React.ReactNode;
  right?: React.ReactNode | string;
  disabled?: boolean;
  checked?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode | string;
  note?: string;
};

const ListItem: React.FC<Props> = ({ icon, right, note, disabled, checked, onClick, children }) => {
  const { isTapped, tapHandleProps } = useIsTapped();

  const onTouchEnd = (e: React.TouchEvent) => {
    e.stopPropagation();
    tapHandleProps.onTouchEnd(e);
  };

  return (
    <Container
      onClick={disabled ? undefined : onClick}
      onTouchMove={tapHandleProps.onTouchMove}
      onTouchEnd={onTouchEnd}
      data-disabled={disabled}
      isTapped={isTapped}
      withNote={!!note}
      withIcon={!!icon}
    >
      {icon}
      <Content>
        {children}
        {note && <Note>{note}</Note>}
      </Content>
      {checked && !right && (
        <AdditionalWrapper>
          <CheckedIconContainer>
            <CheckedIcon />
          </CheckedIconContainer>
        </AdditionalWrapper>
      )}
      {right && !checked && <AdditionalWrapper>{right}</AdditionalWrapper>}
    </Container>
  );
};

export default ListItem;
