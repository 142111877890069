import React from 'react';
import { useLayer, useMousePositionAsTrigger, UseLayerOptions } from 'react-laag';
import { useSelector, useDispatch } from 'react-redux';
import { ResizeObserver } from '@juggle/resize-observer';
import {
  useLongPress,
  CallableContextResult,
  LongPressResult,
  LongPressOptions,
  LongPressDetectEvents,
} from 'use-long-press';

import { ReduxState } from 'modules';
import { pop } from 'modules/queue/actions';
import { useMobileViewContext } from 'components/boards/MobileViewContext';

import { Options, DROPDOWN_QUEUE_KEY } from './useDropdownVisibility';
import DropdownContext, { useDropdownContext } from './DropdownContext';
import ContentContext from './ContentContext';
import DesktopContent from './DesktopContent';
import MobileContent from './MobileContent';

type TriggerProps = {
  isOpen: boolean;
  onContextMenu: (e: React.MouseEvent<Element>) => void;
  longPressBind: CallableContextResult<LongPressResult<Element>, unknown>;
};

type Props = {
  layerOptions?: Pick<UseLayerOptions, Exclude<keyof UseLayerOptions, 'isOpen'>>;
  content: React.ReactNode;
  isDisabled?: boolean;
  longPressOptions?: LongPressOptions<Element>;
  children: React.ReactNode | ((props: TriggerProps) => React.ReactNode);
};

/**
 * Uses `children` as dropdown trigger and
 * positions content relatively to the mouse position
 */
const MousePositionDropdownBase = ({
  layerOptions,
  content,
  isDisabled,
  longPressOptions,
  children,
}: Props) => {
  const queue = useSelector<ReduxState, ReduxState['queue']>(state => state.queue);
  const dispatch = useDispatch();
  const { isMobileView } = useMobileViewContext();
  const { isOpen, isOpenedLast, hide, show } = useDropdownContext();
  const bind = useLongPress(
    () => {
      if (isMobileView) {
        show();
      }
    },
    {
      cancelOnMovement: true,
      detect: LongPressDetectEvents.TOUCH,
      ...longPressOptions,
    },
  );
  const { handleMouseEvent, resetMousePosition, trigger } = useMousePositionAsTrigger();
  const { renderLayer, layerProps } = useLayer({
    isOpen,
    auto: true,
    trigger,
    triggerOffset: 10,
    onOutsideClick: () => {
      if (isMobileView) {
        return null;
      }

      if (isOpenedLast) {
        hide('Click');
        resetMousePosition();
      }
    },
    ResizeObserver,
    ...layerOptions,
  });

  const onContextMenu = (e: React.MouseEvent<Element>) => {
    if (isDisabled || isMobileView) {
      return null;
    }

    if (!isOpen) {
      /**
       * It's a hack but we need somehow to close last opened dropdown when `onContextMenu` is called.
       * github issue: https://github.com/everweij/react-laag/issues/70
       */
      const lastAddedQueueItem = queue[queue.length - 1];

      if (lastAddedQueueItem?.key === DROPDOWN_QUEUE_KEY) {
        dispatch(pop());
      }

      setTimeout(() => {
        show();
      }, 0);
    }

    resetMousePosition();
    handleMouseEvent(e);
  };

  return (
    <>
      {typeof children === 'function' ? (
        children({ isOpen, onContextMenu, longPressBind: bind })
      ) : (
        <div onContextMenu={onContextMenu} {...bind()}>
          {children}
        </div>
      )}
      {isOpen &&
        (isMobileView
          ? renderLayer(
              <div ref={layerProps.ref}>
                <MobileContent>
                  <ContentContext>{content}</ContentContext>
                </MobileContent>
              </div>,
            )
          : renderLayer(
              <div {...layerProps} style={layerProps.style}>
                <DesktopContent>
                  <ContentContext>{content}</ContentContext>
                </DesktopContent>
              </div>,
            ))}
    </>
  );
};

const MousePositionDropdown: React.FC<Props & Options> = ({
  children,
  onShow,
  onHide,
  ...props
}) => (
  <DropdownContext onShow={onShow} onHide={onHide}>
    <MousePositionDropdownBase {...props}>{children}</MousePositionDropdownBase>
  </DropdownContext>
);

export default React.memo<React.FC<Props & Options>>(MousePositionDropdown);
