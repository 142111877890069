import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export type Props = React.HTMLProps<HTMLInputElement> & {
  onFilesAdd: (files: File[]) => void;
};

const FileInput: React.FC<Props> = ({
  id,
  children,
  onChange = () => {},
  onFilesAdd = () => {},
  ...props
}) => {
  const onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    e.preventDefault();

    const target = e.target as HTMLInputElement;
    const files = target.files as File[] | null;

    onFilesAdd(files ? [...files] : []);
    onChange(e);
  };

  /* eslint-disable jsx-a11y/label-has-for */
  return (
    <div>
      {/*
      // @ts-ignore FIXME */}
      <Input id={id} type="file" {...props} onChange={onInputChange} />
      <label htmlFor={id}>{children}</label>
    </div>
  );
  /* eslint-enable jsx-a11y/label-has-for */
};

export default FileInput;
