import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { CurrentUserTrackingPropsQuery } from './__generated__/CurrentUserTrackingPropsQuery';

export const currentUserTrackingPropsQuery = gql`
  query CurrentUserTrackingPropsQuery {
    currentUser {
      id
      name
      email
    }
    currentUserStats {
      createdAt
      lastSession
      activeType
      workspacesCount
      paidWorkspacesCount
      personalBoardsCount
      workspaceBoardsCount
      activeBoardsCount
      initialBoards
    }
  }
`;

const withCurrentUserTrackingProps = <P extends object>(
  ComposedComponent: React.ComponentType<P>,
) => (props: P) => {
  const { data, error } = useQuery<CurrentUserTrackingPropsQuery>(currentUserTrackingPropsQuery, {
    ssr: false,
  });

  // TODO: don't call query if user is not logged in
  if (error || !data?.currentUser) {
    return <ComposedComponent {...props} />;
  }

  return <ComposedComponent userTrackingProps={data} {...props} />;
};

export default withCurrentUserTrackingProps;
