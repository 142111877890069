import { removeByKey } from 'helpers/object';
import reducer from '../reducerCreator';
import * as ActionTypes from './constants';

export type State = {
  [key: string]: boolean;
};

type Handlers = {
  [key in '@visibility/SHOW' | '@visibility/HIDE' | '@visibility/TOGGLE']: (
    payload: { key: string; value: boolean },
    state: State,
  ) => State;
};

const initialState: State = {};

const handlers: Handlers = {
  [ActionTypes.SHOW]: ({ key, value }, state) => ({
    ...state,
    [key]: value || true,
  }),

  [ActionTypes.HIDE]: ({ key }, state) => removeByKey(key, state),

  [ActionTypes.TOGGLE]: ({ key, value }, state: State) => {
    if (state[key]) {
      return removeByKey(key, state);
    }

    return {
      ...state,
      [key]: value || true,
    };
  },
};

export default reducer(handlers, initialState);
