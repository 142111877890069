import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'client/helpers/style-utils';

import ModalPortal from 'client/components/Modal/ModalPortal';
import OnOutsideClick, {
  Container as OnOutsideClickContainer,
} from 'components/OnOutsideClick/OnOutsideClick';
import DraggableSheet from 'client/components/shared/DraggableSheet/DraggableSheet';
import CloseButton from 'components/shared/CloseButton/CloseButton';

import { useMobileViewContext } from 'components/boards/MobileViewContext';

import PopupContext from './PopupContext';

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 16;
  background-color: ${p => p.theme.currentTheme.popupOverlay};
  overflow: scroll;

  ${mediaSelector.mobile} {
    display: block;
    background-color: ${p => p.theme.currentTheme.popupContent};
  }

  ${OnOutsideClickContainer} {
    height: auto;
  }
`;

const CloseButtonContainer = styled.div<{ isMobileHidden: boolean }>`
  position: absolute;
  right: 10px;
  top: 10px;

  ${mediaSelector.mobile} {
    display: ${p => (p.isMobileHidden ? 'none' : 'block')};
  }
`;

const Container = styled(({ width, minHeight, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  width: ${p => p.width}px;
  max-width: ${p => p.width}px;
  ${p =>
    p.minHeight &&
    `
    min-height: ${p.minHeight}px;
  `}
  background-color: ${p => p.theme.currentTheme.popupContent};
  border-radius: 10px;
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray};
  position: relative;

  ${mediaSelector.mobile} {
    width: 100%;
    height: 100%;
    min-height: 100%;
    border-radius: 0px;
    box-shadow: none;
    background-color: transparent;
  }
`;

const Content = styled.div`
  display: grid;
  flex: 1;
  min-height: inherit;
`;

type Props = {
  isOpen: boolean;
  draggableSheet?: boolean;
  width?: number;
  minHeight?: number;
  onOpen?: () => void;
  onClose?: (e?: React.MouseEvent<Element> | MouseEvent | KeyboardEvent) => void;
};

export default function Popup({
  isOpen,
  draggableSheet = true,
  width = 707,
  minHeight,
  onOpen,
  onClose,
  children,
}: PropsWithChildren<Props>) {
  const { isMobileView } = useMobileViewContext();

  if (!isOpen) {
    return null;
  }

  const content = (
    <Container width={width} minHeight={minHeight} role="dialog" draggableSheet={draggableSheet}>
      {typeof onClose === 'function' && (
        <CloseButtonContainer isMobileHidden={draggableSheet}>
          <CloseButton onClick={onClose} />
        </CloseButtonContainer>
      )}
      <Content
        onMouseUp={(e: React.MouseEvent<HTMLDivElement>) =>
          e.nativeEvent.stopImmediatePropagation()
        }
      >
        <PopupContext>{children}</PopupContext>
      </Content>
    </Container>
  );

  if (draggableSheet && isMobileView) {
    return (
      <DraggableSheet
        onOpen={onOpen}
        onClose={() => {
          if (typeof onClose === 'function') {
            onClose();
          }
        }}
      >
        {content}
      </DraggableSheet>
    );
  }

  return (
    <ModalPortal onMount={onOpen}>
      <Overlay onMouseDown={e => e.stopPropagation()}>
        <OnOutsideClick
          onClick={e => {
            if (typeof onClose === 'function') {
              onClose(e);
            }
          }}
          bindToKey={27}
        >
          {content}
        </OnOutsideClick>
      </Overlay>
    </ModalPortal>
  );
}
