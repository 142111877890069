import React from 'react';
import styled from 'styled-components';
import { Trans } from 'lib/i18n';

const Container = styled.div`
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${p => p.theme.currentTheme.notes};
  text-align: center;

  strong {
    color: ${p => p.theme.currentTheme.primary};
  }
`;

const Note: React.FC<{ email: string }> = ({ email }) => (
  <Container>
    <Trans i18nKey="settings:account.confirmEmail.note" values={{ email }} />
  </Container>
);

export default Note;
