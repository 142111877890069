const copyToClipboard = (value: string): boolean => {
  const textarea = document.createElement('textarea');
  let result = false;

  try {
    textarea.setAttribute('readonly', 'true');
    textarea.setAttribute('contenteditable', 'true');
    textarea.style.opacity = '0';
    textarea.style.position = 'fixed';
    textarea.value = value;

    document.body.appendChild(textarea);
    textarea.select();

    const range = document.createRange();

    range.selectNodeContents(textarea);

    const sel = window.getSelection();

    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  } finally {
    document.body.removeChild(textarea);
  }

  return result;
};

export default copyToClipboard;
