import React from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'client/helpers/style-utils';

import { Control } from 'components/shared/Button/Button';
import Icon from './TriggerIcon.svg';

export type Props = {
  width?: string;
  height?: string;
  isActive?: boolean;
  isInversed?: boolean;
};

export const Container = styled(({ width, height, ...props }) => <Control {...props} />)<Props>`
  width: ${p => p.width};
  height: ${p => p.height};

  ${p =>
    p.isInversed &&
    `
    background-color: ${p.isActive ? p.theme.currentTheme.body : 'transparent'};

    &:hover {
      background-color: ${p.theme.currentTheme.body};  
    }
  `}

  ${mediaSelector.mobile} {
    width: 28px;
    height: 28px;
  }
`;

// &:hover {
//   background-color: ${p.theme.currentTheme.body};

//   path {
//     fill: ${p.theme.currentTheme.primary};
//   }
// }

const Trigger = React.forwardRef<HTMLDivElement, Props>(
  ({ width = '24px', height = '24px', ...props }, ref) => (
    <Container ref={ref} {...props} {...{ width, height }} preferInlineIconStyles={true}>
      <Icon />
    </Container>
  ),
);

export default Trigger;
