import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'lib/i18n';

import { mediaSelector } from 'helpers/style-utils';

import ModalHandler from 'components/Modal/Modal';
import StyledModalContent from 'components/Modal/StyledModalContent';
import ConfirmNote from 'components/account/confirm-email/Note';
import ConfirmEmailForm from 'components/account/confirm-email/Form';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 29px 30px 30px;
`;

const Title = styled.h1`
  font-size: 3.8rem;
  line-height: 90%;
  letter-spacing: -0.04em;
  margin-bottom: 10px;
`;

const NoteContainer = styled.div`
  margin-bottom: 20px;
  padding: 0 15%;

  ${mediaSelector.mobile} {
    padding: 0;
  }
`;

type Props = {
  email: string;
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onConfirmSuccess?: () => void;
};

const Modal: React.FC<Props> = ({
  email,
  isOpen,
  onOpen,
  onClose,
  onConfirmSuccess = () => {},
}) => {
  const { t } = useTranslation('settings');

  return (
    <ModalHandler isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
      <StyledModalContent width={480} onClose={onClose}>
        <Container>
          <Title>{t('account.confirmEmail.title')}</Title>
          <NoteContainer>
            <ConfirmNote email={email} />
          </NoteContainer>
          <ConfirmEmailForm
            onEmailConfirmSuccess={() => {
              if (onClose) onClose();
              onConfirmSuccess();
            }}
          />
        </Container>
      </StyledModalContent>
    </ModalHandler>
  );
};

export default Modal;
