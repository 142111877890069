import React from 'react';
import { useDropdownContext } from './DropdownContext';

const DropdownHider: React.FC<{}> = ({ children }) => {
  const { hide } = useDropdownContext();

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <span onClick={() => hide()}>{children}</span>;
};

export default DropdownHider;
