import { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import { systemColorSchemeSupported } from 'helpers/dom';
import * as ActionCreators from 'modules/theme/actions';
import { Themes } from 'styles/themes';
import { ReduxState } from 'modules';

const useTheme = () => {
  const dispatch = useDispatch();
  const { currentTheme, systemThemeSync } = useSelector<ReduxState, ReduxState['theme']>(
    state => state.theme,
    shallowEqual,
  );
  const setTheme = useCallback((theme: Themes) => dispatch(ActionCreators.setTheme(theme)), []);
  const toggleTheme = useCallback(() => dispatch(ActionCreators.toggleTheme()), []);
  const toggleSystemSync = useCallback(() => dispatch(ActionCreators.toggleSystemSync()), []);

  return {
    currentTheme,
    systemThemeSync: systemColorSchemeSupported() ? systemThemeSync : false,
    setTheme,
    toggleTheme,
    toggleSystemSync,
  };
};

export default useTheme;
