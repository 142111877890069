import React from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';

import { Control } from 'components/shared';
import CloseIcon from 'vector/CloseIcon.svg';

const CloseButton = styled(Control)`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-left: 5px;

  path {
    fill: ${p => p.theme.currentTheme.buttonIcon};
  }

  ${mediaSelector.hoverSupported} {
    &:hover {
      background-color: ${p => p.theme.currentTheme.body};

      path {
        fill: ${p => p.theme.currentTheme.primary};
      }
    }
  }
`;

const StyledTag = styled.div`
  font-size: 1.8rem;
  line-height: 2.178rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 34px;
  padding: 6px 4px 6px 8px;
  margin-bottom: 4px;
  background-color: ${p => p.theme.currentTheme.active};
  border-radius: 4px;
  margin-right: 4px;
`;

interface Props {
  label: string;
  onRemove?: () => void;
}

const Tag: React.FC<Props> = ({ label, onRemove }) => (
  <StyledTag>
    {label}
    {onRemove && (
      <CloseButton onClick={onRemove}>
        <CloseIcon width={8} height={8} />
      </CloseButton>
    )}
  </StyledTag>
);

export default Tag;
