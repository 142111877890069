export const SHOW = '@visibility/SHOW';
export const HIDE = '@visibility/HIDE';
export const TOGGLE = '@visibility/TOGGLE';

export const MOBILE_FOOTER_SHOW = 'mobileFooterShow';

export const CREATE_BOARD_FORM = 'createBoardForm';
export const CREATE_BOARD_FORM_BEFORE = 'createBoardFormBefore';
export const CREATE_BOARD_FORM_AFTER = 'createBoardFormAfter';
export const EDIT_BOARD_FORM = 'editBoardForm';

export const CREATE_COLUMN_FORM = 'createColumnForm';
export const CREATE_COLUMN_FORM_AFTER = 'createColumnFormAfter';
export const EDIT_COLUMN_FORM = 'editColumnForm';

export const CREATE_HEADING_FORM = 'createHeadingForm';
export const CREATE_HEADING_FORM_BEFORE = 'createHeadingFormBefore';
export const CREATE_HEADING_FORM_AFTER = 'createHeadingFormAfter';
export const EDIT_HEADING_FORM = 'editHeadingForm';

export const CREATE_CARD_FORM = 'createCardForm';
export const CREATE_CARD_FORM_BEFORE = 'createCardFormBefore';
export const CREATE_CARD_FORM_AFTER = 'createCardFormAfter';
export const EDIT_CARD_FORM = 'editCardForm';

export const CREATE_HEADING_CARD_FORM = 'createHeadingCardForm';
export const CREATE_HEADING_CARD_FORM_BEFORE = 'createHeadingCardFormBefore';
export const CREATE_HEADING_CARD_FORM_AFTER = 'createHeadingCardFormAfter';
export const EDIT_HEADING_CARD_FORM = 'editHeadingCardForm';

export const CREATE_SUB_CARD_FORM = 'createSubCardForm';
export const CREATE_SUB_CARD_FORM_BEFORE = 'createSubCardFormBefore';
export const CREATE_SUB_CARD_FORM_AFTER = 'createSubCardFormAfter';
export const EDIT_SUB_CARD_FORM = 'editSubCardForm';

export const CREATE_ARCHIVED_CARD_FORM_AFTER = 'createArchivedCardFormAfter';
export const EDIT_ARCHIVED_CARD_FORM = 'editArchivedCardForm';

export const EDIT_OPEN_CARD_TITLE_FORM = 'editOpenCardTitleForm';

export const GROWL = 'growl';

export const NOTIFICATIONS_PANEL = 'notificationsPanel';
export const SEARCH = 'search';

export const BOARD_SETTINGS = 'boardSettings';
export const BOARD_SHARING = 'boardSharing';
export const BOARD_SETTINGS_TOOLBAR = 'boardSettingsToolbar';
export const BOARD_TRASH = 'boardTrash';

export const PROJECT_INVITE = 'projectInvite';

export const parentTypeToCardFormVisibilityKey = {
  List: {
    CREATE_CARD_FORM,
    CREATE_CARD_FORM_BEFORE,
    CREATE_CARD_FORM_AFTER,
    EDIT_CARD_FORM,
  },
  Heading: {
    CREATE_CARD_FORM: CREATE_HEADING_CARD_FORM,
    CREATE_CARD_FORM_AFTER: CREATE_HEADING_CARD_FORM_AFTER,
    EDIT_CARD_FORM: EDIT_HEADING_CARD_FORM,
  },
  Card: {
    CREATE_CARD_FORM: CREATE_SUB_CARD_FORM,
    CREATE_CARD_FORM_BEFORE: CREATE_SUB_CARD_FORM_BEFORE,
    CREATE_CARD_FORM_AFTER: CREATE_SUB_CARD_FORM_AFTER,
    EDIT_CARD_FORM: EDIT_SUB_CARD_FORM,
  },
};

export const BOARD_HAS_BEEN_CHANGED = 'boardHasBeenChanged';

export const WORKING_AREA_VISIBILITY = {
  Project: 'projectHasBeenHidden',
  Board: 'boardHasBeenHidden',
};
