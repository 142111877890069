import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSpring, a, config } from 'react-spring';
import { useDrag, useScroll } from 'react-use-gesture';
import useResizeObserver from 'use-resize-observer/polyfilled';

import { hideScrollbar } from 'client/helpers/style-utils';

const MAX_HEIGHT = 80;

const Backdrop = styled(a.div)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  background: #0000007a;
  z-index: 99;
`;

export const Container = styled(a.div)`
  z-index: 100;
  position: fixed;
  left: 2vw;
  right: 2vw;
  bottom: -100vh;
  height: 100vh;
  border-radius: 29px 29px 0px 0px;
  background: ${p => p.theme.currentTheme.dropdownContent};
  touch-action: none;
`;

const ScrollContainer = styled.div<{ isScrollable: boolean }>`
  max-height: ${MAX_HEIGHT}vh;
  overflow-y: ${p => (p.isScrollable ? 'scroll' : 'initial')};
  ${hideScrollbar()}
`;

const Handler = styled.div<{ isHidden: boolean }>`
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease;
  opacity: ${p => (p.isHidden ? '0' : '1')};
  visibility: ${p => (p.isHidden ? 'hidden' : 'visible')};

  &:before {
    content: '';
    background: ${p => p.theme.currentTheme.delimiter};
    display: block;
    width: 28px;
    height: 4px;
    border-radius: 10px;
  }
`;

const Wrapper = styled.div`
  padding: 10px 10px 5vh;
`;

type RenderProps = {
  onClose: () => Promise<void>;
};

type Props = {
  onOpen?: () => void;
  onClose: () => void;
  children: React.ReactNode | ((props: RenderProps) => React.ReactNode);
};

const DraggableSheet = ({ onOpen = () => {}, onClose, children }: Props) => {
  const [initHeight, setInitHeight] = useState(0);
  const [isAtTheTop, setIsAtTheTop] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const { ref: heightRef, height = 0 } = useResizeObserver();
  const { ref: contentHeightRef, height: contentHeight = 0 } = useResizeObserver();
  const [{ y }, set] = useSpring(() => ({
    y: 0,
  }));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const open = ({ canceled }: { canceled: boolean }) => {
    set({ y: -height, immediate: false, config: canceled ? config.wobbly : config.stiff });
  };

  const close = async (velocity = 0) => {
    await set({
      y: 0,
      immediate: false,
      config: {
        ...config.stiff,
        velocity,
        clamp: true,
      },
    });
    onClose();
  };

  const toggleHandler = (y: number) => {
    if (y <= 50) {
      if (!isAtTheTop) {
        setIsAtTheTop(true);
      }
    } else if (isAtTheTop) {
      setIsAtTheTop(false);
    }
  };

  useEffect(() => {
    onOpen();
  }, []);

  useEffect(() => {
    if (!initHeight && height) {
      setInitHeight(height);
    }
  }, [initHeight, height]);

  useEffect(() => {
    if (height) {
      setIsScrollable((contentHeight / window.innerHeight) * 100 >= MAX_HEIGHT);
      set({ y: -height, immediate: false, config: config.stiff });
      toggleHandler(0);
    }
  }, [height, set]);

  useEffect(() => {
    if (isScrollable) {
      setIsAtTheTop(true);
    }
  }, [isScrollable]);

  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel, canceled }) => {
      if (my < -90) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        if (my > height / 2 || vy > 0.5) {
          close(vy);
        } else {
          open({ canceled });
        }
      } else {
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        set({ y: -height + my, immediate: true });
      }
    },
    {
      filterTaps: true,
      useTouch: isScrollable,
      bounds: { top: 0 },
      rubberband: true,
    },
  );

  const scrollBind = useScroll(({ xy: [, y] }) => {
    toggleHandler(y);
  });

  const bgStyle = {
    opacity: y.to({
      range: [0, initHeight * -1],
      output: [0, 0.4],
    }),
  };

  return (
    <>
      <Backdrop onClick={() => close()} style={bgStyle} />
      <Container {...(isScrollable ? {} : bind())} style={{ y }}>
        <div ref={heightRef}>
          {isScrollable && <Handler {...bind()} isHidden={!isAtTheTop} />}
          <ScrollContainer isScrollable={isScrollable} {...scrollBind()}>
            <Wrapper ref={contentHeightRef}>
              {typeof children === 'function' ? children({ onClose: close }) : children}
            </Wrapper>
          </ScrollContainer>
        </div>
      </Container>
    </>
  );
};

export default DraggableSheet;
