import React, { useContext } from 'react';

import useDropdownVisibility, { DropdownVisibilityProps, Options } from './useDropdownVisibility';

const contextValue: DropdownVisibilityProps = {
  isOpen: false,
  isOpenedLast: false,
  show: () => {},
  hide: () => {},
  toggle: () => {},
};

export const Context = React.createContext(contextValue);

const DropdownContext: React.FC<Options> = ({ children, ...props }) => {
  const value = useDropdownVisibility(props);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useDropdownContext = () => useContext(Context);

export default DropdownContext;
