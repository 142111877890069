import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLayer, useHover } from 'react-laag';
import { ResizeObserver } from '@juggle/resize-observer';
import { isTouchDevice } from 'helpers/dom';

export const Handler = styled.div``;

const Container = styled.div`
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${p => p.theme.colors.white};
  white-space: nowrap;
  text-align: center;
  padding: 5px 12px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 18px;
  z-index: 19;
`;

type Props = {
  content: React.ReactNode;
  disable?: boolean;
  disabled?: boolean;
  contentStyle?: React.CSSProperties;
};

const Tooltip: React.FC<Props> = ({ content, children, contentStyle = {}, disable, disabled }) => {
  const [isOver, hoverProps, close] = useHover();
  const { triggerProps, renderLayer, layerProps } = useLayer({
    isOpen: isOver,
    auto: true,
    triggerOffset: 5,
    ResizeObserver,
  });

  useEffect(() => {
    if (disable) {
      close();
    }
  }, [disable]);

  return (
    <>
      <Handler ref={triggerProps.ref} {...(isTouchDevice || disabled || disable ? {} : hoverProps)}>
        {children}
      </Handler>
      {isOver &&
        renderLayer(
          <Container {...layerProps} style={{ ...layerProps.style, ...contentStyle }}>
            {content}
          </Container>,
        )}
    </>
  );
};

export default Tooltip;
