import 'lib/polyfills';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import App, { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Provider, ProviderProps } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import dynamic from 'next/dynamic';

import ThemeProvider from 'styles/ThemeProvider';
import withApollo from 'lib/withApollo';
import { wrapper } from 'lib/store';
import redirect from 'lib/redirect';
import { TrackingProvider } from 'lib/track';
import { AppContext } from 'lib/types/context';

import { appWithTranslation } from 'lib/i18n';
import PageHeader from 'components/PageHeader/PageHeader';
import { env, analytics } from 'config/env';

const ConfirmEmailOverlay = dynamic(
  () => import('components/ConfirmEmailOverlay/ConfirmEmailOverlay'),
  {
    ssr: false,
    loading: () => null,
  },
);

type Props = AppProps &
  ProviderProps & {
    isLoggedIn: boolean;
  };

const ColumnsApp = ({ Component, ...rest }: Props) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const persistor = persistStore(store, {}, () => {
    persistor.persist();
  });
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', url => {
      if (env.production) {
        // @ts-ignore FIXME
        window.gtag('config', analytics.googleTrackingId, {
          page_path: url, // eslint-disable-line @typescript-eslint/camelcase
        });
      }
    });
  }, [router]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        {() => (
          <ThemeProvider>
            <PageHeader />
            <TrackingProvider>
              <div style={{ height: '100%' }}>
                <Component {...props.pageProps} />
                {props.isLoggedIn && <ConfirmEmailOverlay />}
              </div>
            </TrackingProvider>
          </ThemeProvider>
        )}
      </PersistGate>
    </Provider>
  );
};

ColumnsApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (appProps.pageProps.protected && !appContext.isLoggedIn) {
    redirect(appContext.ctx, '/signin');
  }

  return {
    ...appProps,
    isLoggedIn: appContext.isLoggedIn,
  };
};

export default compose<any, any>(withApollo, wrapper.withRedux, appWithTranslation)(ColumnsApp);
