import { gql } from '@apollo/client';
import userItemFragment from 'components/users/UserItem/userItemFragment';

const boardMembershipFragment = gql`
  fragment BoardMembershipFragment on BoardMembership {
    id
    accessLevel
    user {
      ...UserItem
    }
    board {
      id
    }
  }
  ${userItemFragment}
`;

export default boardMembershipFragment;
