import React, { useContext, useMemo } from 'react';

import useWindowSize from 'hooks/useWindowSize';
import { MOBILE_WIDTH } from 'helpers/style-utils';

type MobileViewContextType = {
  isMobileView: boolean;
};

const context: MobileViewContextType = {
  isMobileView: false,
};

const Context = React.createContext(context);

const MobileViewContext: React.FC<{}> = ({ children }) => {
  const windowSize = useWindowSize();
  const isMobileView = windowSize.width ? windowSize.width <= MOBILE_WIDTH : false;
  const providerValue = useMemo(() => ({ isMobileView }), [isMobileView]);

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};

export const useMobileViewContext = () => useContext(Context);

export default MobileViewContext;
