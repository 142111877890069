import { removeItem } from 'helpers/array';
import reducer from '../reducerCreator';
import * as ActionTypes from './constants';

export type State = { id: number; key: string }[];

const initialState: State = [];

type Action = {
  id: number;
  key: string;
};

const handlers = {
  [ActionTypes.PUSH]: (item: Action, state: State): State => [...state, item],

  [ActionTypes.POP]: (_: Action, state: State): State => removeItem(state, state.length - 1),

  [ActionTypes.REMOVE]: ({ id }: Action, state: State): State => {
    const index = state.findIndex(item => item.id === id);

    if (index >= 0) {
      return removeItem(state, index);
    }

    return state;
  },

  [ActionTypes.CLEAR_QUEUE]: () => initialState,
};

export default reducer(handlers, initialState);
