import { gql } from '@apollo/client';
import userItemFragment from 'components/users/UserItem/userItemFragment';

const projectMembershipFragment = gql`
  fragment ProjectMembershipFragment on ProjectMembership {
    id
    status
    user {
      ...UserItem
    }
    project {
      id
    }
  }
  ${userItemFragment}
`;

export default projectMembershipFragment;
