import React, { Component } from 'react';

import styled from 'styled-components';

import { isClickedOutside } from 'helpers/dom';

export const Container = styled.div`
  height: 100%;
`;

interface Props {
  style?: { [key: string]: string };
  bindToKey?: number;
  onClick: (e: MouseEvent | KeyboardEvent) => any;
  children: React.ReactNode;
}

class OnOutsideClick extends Component<Props> {
  node: React.ReactNode;

  componentDidMount() {
    const { bindToKey } = this.props;
    document.addEventListener('mouseup', this.onOutsideClick, false);

    if (bindToKey) {
      document.addEventListener('keydown', this.onKeyDown, false);
    }
  }

  componentWillUnmount() {
    const { bindToKey } = this.props;
    document.removeEventListener('mouseup', this.onOutsideClick, false);

    if (bindToKey) {
      document.removeEventListener('keydown', this.onKeyDown, false);
    }
  }

  onOutsideClick = (e: MouseEvent) => {
    const { onClick } = this.props;
    if (isClickedOutside(e, this.node)) {
      onClick(e);
    }
  };

  onKeyDown = (e: KeyboardEvent) => {
    const { bindToKey, onClick } = this.props;
    if (e.keyCode === bindToKey) {
      onClick(e);
    }
  };

  render() {
    const { style, children } = this.props;

    return (
      <Container ref={node => (this.node = node)} style={style}>
        {children}
      </Container>
    );
  }
}

export default OnOutsideClick;
