import React from 'react';
import styled from 'styled-components';

import useIsTapped from 'hooks/useIsTapped';
import { mediaSelector } from 'helpers/style-utils';

import { Rounded } from 'components/shared';

import AddButtonIcon from './AddButtonIcon.svg';

type ContainerProps = {
  isActive?: boolean;
  isTapped: boolean;
};

export const Container = styled(({ isActive, isTapped, ...props }: ContainerProps) => (
  <Rounded as="button" {...props} />
))`
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: -0.054rem;
  color: ${({ theme: { currentTheme } }) => currentTheme.buttonText};
  padding: 0 10px 0 9px;
  text-align: left;
  min-height: 32px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }

  path {
    transition: stroke 0.2s ease;
    stroke: ${p => p.theme.currentTheme.buttonIcon};
  }

  ${mediaSelector.hoverSupported} {
    &:hover {
      color: ${p => p.theme.currentTheme.text};
      background-color: ${p => p.theme.currentTheme.hover};

      path {
        stroke: ${p => p.theme.colors.dodgerBlue};
      }
    }
  }

  ${p =>
    p.isTapped &&
    `
    transition:
      opacity 0s ease,
      background-color 0s ease
    ;
    color: ${p.theme.currentTheme.text};
    background-color: ${p.theme.colors.hawkesBlue};

    && {
      path {
        fill: ${p.theme.colors.dodgerBlue};
      }
    }
  `}

  ${mediaSelector.mobile} {
    font-size: 1.8rem;
    line-height: 2.1rem;

    svg {
      margin-right: 9px;
    }
  }
`;

const Text = styled.div`
  opacity: 0.8;
`;

type Props = {
  isActive?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const AddButton: React.FC<Props> = ({ children, isActive, ...rest }) => {
  const { isTapped, tapHandleProps } = useIsTapped();

  return (
    <Container {...{ isActive, isTapped }} {...rest} {...tapHandleProps}>
      <AddButtonIcon />
      <Text>{children}</Text>
    </Container>
  );
};

export default AddButton;
