// @ts-ignore FIXME
export default function reducerCreator(handlers = {}, initialState) {
  // @ts-ignore FIXME
  return (state = initialState, action) => {
    // @ts-ignore FIXME
    const handler = handlers[action.type];

    if (!handler || typeof handler !== 'function') {
      return state;
    }

    return handler(action, state);
  };
}
