import React from 'react';
import styled from 'styled-components';

import useIsTapped from 'hooks/useIsTapped';

import { mediaSelector } from 'helpers/style-utils';

import { MobileHidden, MobileVisible } from 'components/shared/Visibility';
import CheckedIcon from 'vector/CheckedIcon.svg';
import CheckedMobileIcon from 'components/shared/svg/CheckedMobileIcon.svg';
import ListItemHelper from './ListItemHelper';

const Container = styled.div`
  position: relative;
`;

const IconWrapper = styled.div<{ isMobileVisible?: boolean }>`
  position: absolute;
  left: 16px;
  top: 6px;

  path,
  circe,
  rect {
    fill: ${p => p.theme.currentTheme.buttonIcon};
  }

  ${mediaSelector.mobile} {
    display: none;

    ${p =>
      p.isMobileVisible &&
      `
      left: 19px;
      top: 11px;
      display: block;
    `}
  }
`;

const MobileIconWrapper = styled(IconWrapper)`
  display: none;

  ${mediaSelector.mobile} {
    top: 8px;
    display: block;
  }
`;

const HelperWrapper = styled.div`
  color: ${p => p.theme.colors.bombay};
`;

const Wrapper = styled.div<{ withIcon: boolean; isTapped: boolean }>`
  padding: 7px 10px 7px ${props => (props.withIcon ? '41px' : '15px')};
  display: flex;

  &[data-disabled='true'] {
    cursor: default;
    opacity: 0.4;
  }

  ${mediaSelector.hoverSupported} {
    &:not([data-disabled='true']):hover {
      background-color: ${p => p.theme.currentTheme.listItemHover};
      cursor: pointer;

      ${IconWrapper} {
        path,
        circle,
        rect,
        text {
          fill: ${p => p.theme.currentTheme.listItemIconHover};
        }

        rect {
          stroke: ${p => p.theme.currentTheme.listItemIconHover};
        }
      }

      ${HelperWrapper} {
        color: ${p => p.theme.currentTheme.listItemIconHover};

        span {
          background-color: ${p => p.theme.currentTheme.body};
          opacity: 0.6;
        }
      }
    }
  }

  ${({ isTapped, theme }) =>
    isTapped &&
    `
    &:not([data-disabled="true"]) {
      background-color: ${theme.currentTheme.listItemHover};

      ${IconWrapper} {
        path, circle, rect, text {
          fill: ${theme.currentTheme.listItemIconHover};
        }

        rect {
          stroke: ${theme.currentTheme.listItemIconHover};
        }
      }
    }
  `}

  ${({ withIcon }: { withIcon: boolean }) => `
    ${mediaSelector.mobile} {
      border-radius: 6px;
      padding-left: ${withIcon ? '52px' : '19px'};
      font-size: 1.8rem;
      line-height: 2.1rem;
      padding-top: 12px;
      padding-bottom: 11px;
    }
  `}
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const AdditionalWrapper = styled.div`
  margin-left: auto;
  color: ${p => p.theme.colors.bombay};
  font-size: 1.4rem;

  ${mediaSelector.mobile} {
    font-size: 1.6rem;
  }
`;

const CheckedIconContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 11px;
`;

interface ListItemProps {
  icon?: React.ReactNode;
  mobileIcon?: React.ReactNode;
  right?: React.ReactNode | string;
  disabled?: boolean;
  checked?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  helper?: string | null;
  shortcuts?: string[];
  children: React.ReactNode | string;
}

const ListItem: React.FC<ListItemProps> = ({
  icon,
  mobileIcon,
  right,
  disabled,
  checked,
  onClick,
  helper,
  shortcuts,
  children,
}) => {
  const { isTapped, tapHandleProps } = useIsTapped();

  const onTouchEnd = (e: React.TouchEvent) => {
    e.stopPropagation();
    tapHandleProps.onTouchEnd(e);
  };

  return (
    <Container
      onClick={disabled ? undefined : onClick}
      onTouchMove={tapHandleProps.onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <Wrapper data-disabled={disabled} withIcon={!!(mobileIcon || icon)} isTapped={isTapped}>
        {icon && <IconWrapper isMobileVisible={!mobileIcon}>{icon}</IconWrapper>}
        {mobileIcon && <MobileIconWrapper>{mobileIcon}</MobileIconWrapper>}

        <ContentWrapper>
          <InnerWrapper>
            <TextWrapper>{children}</TextWrapper>
            {checked && !right && (
              <AdditionalWrapper>
                <CheckedIconContainer>
                  <MobileVisible>
                    <CheckedMobileIcon />
                  </MobileVisible>
                  <MobileHidden>
                    <CheckedIcon />
                  </MobileHidden>
                </CheckedIconContainer>
              </AdditionalWrapper>
            )}
            {right && !checked && <AdditionalWrapper>{right}</AdditionalWrapper>}
          </InnerWrapper>

          {!!helper && (
            <HelperWrapper>
              <ListItemHelper shortcuts={shortcuts}>{helper}</ListItemHelper>
            </HelperWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default ListItem;
