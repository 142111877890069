import React from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';

import CloseButton from 'components/shared/CloseButton/CloseButton';

const Container = styled(({ width, minHeight, ...props }) => <div {...props} />)`
  display: flex;
  flex-direction: column;
  width: ${p => p.width}px;
  max-width: ${p => p.width}px;
  ${p =>
    p.minHeight &&
    `
    min-height: ${p.minHeight}px;
  `}
  background-color: ${p => p.theme.currentTheme.popupContent};
  border-radius: 10px;
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray};
  position: relative;

  ${mediaSelector.mobile} {
    width: 100%;
    height: 100%;
    min-height: 100%;
    border-radius: 0px;
    box-shadow: none;
    background-color: transparent;
  }
`;

const Content = styled.div`
  flex: 1;
  min-height: inherit;
  // display: grid;
  flex-direction: column;

  // ${mediaSelector.mobile} {
  //   padding-bottom: 50px;
  // }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

type Props = {
  width?: number;
  minHeight?: number;
  onClose?: (e: React.MouseEvent<Element>) => void;
};

const StyledModalContent: React.FC<Props> = ({ width = 707, minHeight, onClose, children }) => (
  <Container width={width} minHeight={minHeight} role="dialog">
    {typeof onClose === 'function' && (
      <CloseButtonContainer>
        <CloseButton onClick={onClose} />
      </CloseButtonContainer>
    )}
    <Content
      onMouseUp={(e: React.MouseEvent<HTMLDivElement>) => e.nativeEvent.stopImmediatePropagation()}
    >
      {children}
    </Content>
  </Container>
);

export default StyledModalContent;
