import { useEffect } from 'react';

type Hook = (action: (e: KeyboardEvent) => void, eventProps: { [key: string]: any }) => void;

export const useBindActionToKey: Hook = (action, eventProps) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent): void => {
      // @ts-ignore FIXME
      if (Object.keys(eventProps).every((key: string) => e[key] === eventProps[key])) {
        action(e);
      }
    };

    document.addEventListener('keydown', onKeyDown, false);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [action]);
};

interface Props {
  action: (e: KeyboardEvent) => void;
  eventProps: { [key: string]: any };
  children: JSX.Element;
}

const BindActionToKey = ({ action, eventProps, children }: Props) => {
  useBindActionToKey(action, eventProps);

  return children;
};

export default BindActionToKey;
