import { useEffect } from 'react';
import { useQueue } from 'components/shared/WithQueue/WithQueue';

export type ClosedByUnion = 'Click' | 'Esc';

export type DropdownVisibilityProps = {
  isOpen: boolean;
  isOpenedLast: boolean;
  show: () => void;
  hide: (by?: ClosedByUnion) => void | Promise<void>;
  toggle: (by: ClosedByUnion) => void;
};

export type Options = {
  isOpen?: boolean;
  onShow?: () => void;
  onHide?: (by?: ClosedByUnion) => void;
};

export const DROPDOWN_QUEUE_KEY = 'dropdown';

const useDropdownVisibility = ({
  isOpen,
  onShow = () => {},
  onHide = () => {},
}: Options): DropdownVisibilityProps => {
  const { isQueued, isQueuedLast, pushToQueue, popFromQueue } = useQueue(DROPDOWN_QUEUE_KEY);

  const show = () => {
    if (!isQueued) {
      onShow();
      return pushToQueue();
    }
  };

  const hide = (by?: ClosedByUnion) => {
    if (isQueuedLast) {
      onHide(by);
      popFromQueue();
    }
  };

  const toggle = (by?: ClosedByUnion) => {
    if (!isQueued) {
      return show();
    }

    hide(by);
  };

  useEffect(() => {
    if (isOpen) {
      console.log('effect show ');
      show();
    }
  }, [isOpen, isQueued]);

  return {
    isOpen: isQueued,
    isOpenedLast: isQueuedLast,
    show,
    hide,
    toggle,
  };
};

export default useDropdownVisibility;
