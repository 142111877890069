import React from 'react';

import { getCurrentUser } from 'components/users/WithCurrentUser/WithCurrentUser';
import ConfirmEmailModal from 'components/account/confirm-email/Modal';

const ConfirmEmailOverlay = () => {
  const currentUser = getCurrentUser();

  if (!currentUser || currentUser.emailConfirmed) {
    return null;
  }

  return <ConfirmEmailModal email={currentUser.email || ''} isOpen={true} />;
};

export default ConfirmEmailOverlay;
