import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div`
  text-align: center;
`;

const Status = styled.h1`
  font-size: 14rem;
  line-height: 1;
  letter-spacing: -0.04em;
`;

const Text = styled.div`
  font-size: 1.6rem;
  line-height: 1.9rem;
  letter-spacing: -0.024rem;
  color: ${p => p.theme.currentTheme.notes};
  margin-top: 3px;
`;

const ErrorStatusStub: React.FC<{ status: number; size?: string; text?: string }> = ({
  status = 404,
  size,
  text,
}) => (
  <Container>
    <Wrapper>
      <Status style={{ fontSize: size }}>{status}</Status>
      {text && <Text>{text}</Text>}
    </Wrapper>
  </Container>
);

export default ErrorStatusStub;
