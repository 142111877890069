import { createWrapper } from 'next-redux-wrapper';
import { createStore, combineReducers, applyMiddleware, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers, { ReduxState } from 'modules';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['sidebarCollapse', 'theme'],
};

const rootReducer = combineReducers(reducers);

export const initStore = (initialState = {}) => {
  const middlewares = [thunkMiddleware];

  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  return store;
};

export const wrapper = createWrapper<Store<ReduxState>>(initStore);
