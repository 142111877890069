import { gql, ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CheckLoggedInQuery } from './__generated__/CheckLoggedInQuery';

const checkLodggedIn = ({ query }: ApolloClient<NormalizedCacheObject>) =>
  query<CheckLoggedInQuery>({
    query: gql`
      query CheckLoggedInQuery {
        currentUser {
          id
          name
        }
      }
    `,
  })
    .then(({ data }) => ({ currentUser: data?.currentUser }))
    .catch(() => ({ currentUser: null }));

export default checkLodggedIn;
