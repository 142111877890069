import React from 'react';
import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';
import Icon from './ArrowButtonIcon.svg';

type ThemeType = 'light' | 'dark';

const themeColorMap: { [type in ThemeType]: string } = {
  light: 'rgba(255,255,255,.3)',
  dark: 'rgba(0, 0, 0, 0.2)',
};

type DirectionType = 'left' | 'right';

const directionMap: { [direction in DirectionType]: string } = {
  left: '0deg',
  right: '180deg',
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ type }: { type: ThemeType }) => themeColorMap[type]};
  transition: background-color ease 0.15s;

  ${mediaSelector.hoverSupported} {
    &:hover {
      background-color: ${p => p.theme.colors.dodgerBlue};
    }
  }
`;

const StyledIcon = styled(Icon)`
  transform: rotate(${({ direction }: { direction: DirectionType }) => directionMap[direction]});
`;

interface Props {
  type?: ThemeType;
  dir?: DirectionType;
  size?: number;
  onClick?: (e: React.MouseEvent) => void;
  onDoubleClick?: (e: React.MouseEvent) => void;
}

const ArrowButton: React.FC<Props> = ({ type = 'light', dir = 'left', size = 24, ...rest }) => (
  <Container
    type={type}
    style={{
      width: `${size}px`,
      height: `${size}px`,
    }}
    {...rest}
  >
    <StyledIcon direction={dir} />
  </Container>
);

export default ArrowButton;
