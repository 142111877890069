import styled from 'styled-components';
import { mediaSelector } from 'helpers/style-utils';

export const Body = styled.div`
  background-color: ${p => p.theme.currentTheme.dropdownContent};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 10px 8px;

  hr {
    margin: 9px 2px;
  }

  ${mediaSelector.mobile} {
    box-shadow: none;
    border-radius: 0;
    padding: 0px;
  }
`;
