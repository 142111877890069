import { ApolloLink, Operation, NextLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { OperationDefinitionNode } from 'graphql';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

const isSsr = () => typeof window === 'undefined';

export default class WsApolloLink extends ApolloLink {
  private link: ApolloLink;

  constructor({ uri }: { uri: string }) {
    super();

    if (isSsr()) {
      this.link = ApolloLink.empty();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const ActionCable = require('actioncable');
      const cable = ActionCable.createConsumer(uri);
      this.link = new ActionCableLink({ cable });
    }
  }

  request(operation: Operation, forward: NextLink) {
    const { kind, operation: mainOperation } = getMainDefinition(
      operation.query,
    ) as OperationDefinitionNode;

    if (kind === 'OperationDefinition' && mainOperation === 'subscription') {
      return this.link.request(operation, forward);
    }

    return forward(operation);
  }
}
