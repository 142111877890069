// @ts-ignore FIXME
export const removeByKey = (id, obj = {}) =>
  Object.keys(obj).reduce((acc, key) => {
    if (key === id) {
      return acc;
    }

    return {
      ...acc,
      // @ts-ignore FIXME
      [key]: obj[key],
    };
  }, {});

export const isEmpty = (obj = {}) => Object.keys(obj).length === 0;
