import { getCookie, setCookie } from 'cookies-next';
import jwtDecode from 'jwt-decode';
import { api, app } from 'config/env';

import { expireCookie } from 'helpers/cookie';
import { NextPageContext } from 'next';

let isFirstRequest = true;

type Options = {
  req?: NextPageContext['req'];
  res?: NextPageContext['res'];
};

type AuthToken = {
  accessToken: string;
  tokenType: string;
};

type RefreshToken = {
  token: string;
  expireAt: number;
};

const saveToken = (tokenCookie: string, token: string, exp: number, { req, res }: Options = {}) => {
  setCookie(tokenCookie, token, {
    path: '/',
    expires: new Date(exp * 1000),
    domain: app.sharedCookieHost,
    req,
    res,
  });

  return token;
};

export const saveTokens = (
  authToken: AuthToken | null,
  refreshToken: RefreshToken | null,
  options: Options = {},
) => {
  if (authToken && refreshToken) {
    const authTokenValue = `${authToken.tokenType} ${authToken.accessToken}`;
    const { exp }: { exp: number } = jwtDecode(authTokenValue.split(' ')[1]);

    saveToken(app.tokenCookie, authTokenValue, exp, options);
    saveToken(app.refreshTokenCookie, refreshToken.token, refreshToken.expireAt, options);

    return true;
  }

  return false;
};

export const clearToken = () => {
  expireCookie(app.tokenCookie, app.sharedCookieHost);
  expireCookie(app.refreshTokenCookie, app.sharedCookieHost);
};

const needRefreshToken = (token?: string) => {
  if (token) {
    const { exp }: { exp: number } = jwtDecode(token.split(' ')[1]);
    return exp < Date.now() / 1000;
  }

  return true;
};

export const refreshAuthToken = (refreshToken: string, options: Options = {}) => {
  return fetch(`${api.target}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCookie(app.tokenCookie, options) || '',
    },
    body: JSON.stringify({
      query: `
      mutation RefreshAuthTokenMutation($input: RefreshAuthTokenInput!) {
        refreshAuthToken(input: $input) {
          authToken {
            accessToken
            tokenType
          }
          refreshToken {
            token
            expireAt
          }
          error {
            code
          }
        }
      }
    `,
      variables: {
        input: {
          refreshToken,
        },
      },
    }),
  })
    .then(response => response.json())
    .then(({ data }) => {
      if (!data?.refreshAuthToken) {
        return null;
      }

      const { authToken, refreshToken } = data.refreshAuthToken;

      if (saveTokens(authToken, refreshToken, options)) {
        return getCookie(app.tokenCookie, options) || null;
      }

      return null;
    });
};

export const withToken = (
  accessToken?: string,
  refreshToken?: string,
  req?: NextPageContext['req'],
  res?: NextPageContext['res'],
) => {
  if (isFirstRequest || needRefreshToken(accessToken)) {
    isFirstRequest = false;
    return refreshAuthToken(refreshToken || '', { req, res });
  }

  return Promise.resolve(accessToken);
};

// @ts-ignore FIXME
export const oauthCallback = ({ code }) =>
  fetch(`${api.target}/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
        mutation SigninViaProviderMutation($input: SigninViaProviderInput!) {
          signinViaProvider(input: $input) {
            authToken {
              accessToken
              tokenType
            }
            refreshToken {
              token
              expireAt
            }
            user {
              name
              slug
            }
            error {
              code
            }
          }
        }
      `,
      variables: {
        input: {
          provider: 'google',
          token: code,
        },
      },
    }),
  })
    .then(response => response.json())
    .then(({ data }) => {
      if (!data?.signinViaProvider) {
        return null;
      }

      const { authToken, refreshToken } = data.signinViaProvider;

      if (saveTokens(authToken, refreshToken)) {
        return data;
      }

      return undefined;
    });
