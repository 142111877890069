import React from 'react';
import styled, { css } from 'styled-components';
import { mediaSelector } from 'helpers/style-utils';

type Display = Pick<React.CSSProperties, 'display'>;

export const mobileVisible = css<Display>`
  display: none;

  ${mediaSelector.mobile} {
    display: ${p => p.display || 'block'};
  }
`;

export const mobileHidden = css<Display>`
  display: ${p => p.display || 'block'};

  ${mediaSelector.mobile} {
    display: none;
  }
`;

export const MobileVisible = styled.div`
  ${mobileVisible}
`;

export const MobileHidden = styled.div`
  ${mobileHidden}
`;
