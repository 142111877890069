import reducer from '../reducerCreator';
import * as ActionTypes from './constants';

export type State = {
  isCollapsed: boolean;
  isMobileCollapsed: boolean;
  isDragging: boolean;
};

type Handlers = {
  [key in
    | '@sidebarCollapse/TOGGLE_SIDEBAR_COLLAPSE'
    | '@sidebarCollapse/SHOW_MOBILE_SIDEBAR'
    | '@sidebarCollapse/HIDE_MOBILE_SIDEBAR'
    | '@sidebarCollapse/ON_SIDEBAR_DRAG_START'
    | '@sidebarCollapse/ON_SIDEBAR_DRAG_END']: (action: any, state: State) => State;
};

const initialState: State = {
  isCollapsed: false,
  isMobileCollapsed: false,
  isDragging: false,
};

const handlers: Handlers = {
  [ActionTypes.TOGGLE_SIDEBAR_COLLAPSE]: (_action, state) => ({
    ...state,
    isCollapsed: !state.isCollapsed,
  }),

  [ActionTypes.SHOW_MOBILE_SIDEBAR]: (_action, state) => ({
    ...state,
    isMobileCollapsed: false,
  }),

  [ActionTypes.HIDE_MOBILE_SIDEBAR]: (_action, state) => ({
    ...state,
    isMobileCollapsed: true,
  }),

  [ActionTypes.ON_SIDEBAR_DRAG_START]: (_action, state) => ({
    ...state,
    isDragging: true,
  }),

  [ActionTypes.ON_SIDEBAR_DRAG_END]: (_action, state) => ({
    ...state,
    isDragging: false,
  }),
};

export default reducer(handlers, initialState);
