import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
  NextLink,
} from '@apollo/client';
import { NextPageContext } from 'next';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-unfetch';
import { BatchHttpLink } from 'apollo-link-batch-http';

import { api } from 'config/env';
import possibleTypes from '__generated__/possibleTypes.json';
import { withToken } from './withTokenMiddleware';
import WsApolloLink from './WsApolloLink';

const isSsr = () => !process.browser;

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

if (isSsr()) {
  // @ts-ignore FIXME
  global.fetch = fetch;
}

class BatchLink extends ApolloLink {
  private link: BatchHttpLink;

  constructor({ uri }: { uri: string }) {
    super();
    this.link = new BatchHttpLink({
      uri,
      batchMax: 500,
    });
  }

  // @ts-ignore
  request(operation: Operation, forward: NextLink) {
    if (operation.getContext().isBatchRequest) {
      // @ts-ignore apollo client have incomplete operation definition
      return this.link.request(operation);
    }
    return forward(operation);
  }
}

export type ApolloClientOptions = {
  getToken: () => string | undefined;
  getRefreshToken: () => string | undefined;
  req?: NextPageContext['req'];
  res?: NextPageContext['res'];
};

const createClient = (
  initialState: NormalizedCacheObject,
  { getToken, getRefreshToken, req, res }: ApolloClientOptions,
) => {
  const httpLink = createUploadLink({
    uri: `${api.target}/graphql`,
  });

  const wsLink = new WsApolloLink({
    uri: `${api.socketsTarget}/cable`,
  });

  const authLink = setContext(() => {
    const accessToken = getToken();
    const refreshToken = getRefreshToken();

    return withToken(accessToken, refreshToken, req, res).then(token => ({
      headers: {
        authorization: token,
      },
    }));
  });

  const batchLink = new BatchLink({
    uri: `${api.target}/graphql`,
  });

  return new ApolloClient({
    connectToDevTools: !isSsr(),
    ssrMode: isSsr(),
    // @ts-ignore FIXME
    link: ApolloLink.from([authLink, wsLink, batchLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        Board: {
          fields: {
            invites: {
              merge(_existing = [], incoming) {
                return incoming;
              },
            },
          },
        },
        Project: {
          fields: {
            projectBoards: {
              merge(_existing = [], incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }).restore(initialState || {}),
    assumeImmutableResults: true,
  });
};

const initApolloClient = (initialState: NormalizedCacheObject, options: ApolloClientOptions) => {
  if (isSsr()) {
    return createClient(initialState, options);
  }

  if (!apolloClient) {
    apolloClient = createClient(initialState, options);
  }

  return apolloClient;
};

export default initApolloClient;
