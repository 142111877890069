import { gql } from '@apollo/client';
import { userpicFragment } from 'components/shared/Userpic/Userpic';

const userItemFragment = gql`
  fragment UserItem on User {
    id
    name
    slug
    profileImage {
      ...UserpicFragment
    }
  }
  ${userpicFragment}
`;

export default userItemFragment;
