const { APP_ENV, API_HTTP_AUTH_PASS, API_HTTP_AUTH_USER } = process.env;

const HOST = process.env.HOST || 'localhost';
const PORT = process.env.PORT || 8080;
const SHARED_COOKIE_HOST = process.env.SHARED_COOKIE_HOST || 'localhost';

const API_HOST = process.env.API_HOST || 'localhost';
const API_PORT = process.env.API_PORT || 3302;

const isProduction = APP_ENV === 'production';
const isStaging = APP_ENV === 'staging';
const isDevelopment = !isProduction && !isStaging;

const getTargetUrl = (h, p, https) => `http${https ? 's' : ''}://${h}${p ? `:${p}` : ''}`;

const getSocketsTargetUrl = (h, p, https) => {
  const port = p ? `:${p}` : '';
  return `ws${https ? 's' : ''}://${h}${isDevelopment || https ? port : ''}`;
};

const config = {
  env: {
    development: isDevelopment,
    production: isProduction,
    staging: isStaging,
    serviceName: process.env.SERVICE_NAME || 'columns-client',
    name: APP_ENV,
  },
  app: {
    host: HOST,
    port: PORT,
    target: getTargetUrl(HOST, PORT, isProduction || isStaging),
    sharedCookieHost: SHARED_COOKIE_HOST,
    tokenCookie: isStaging ? 'accessTokenStaging' : 'accessToken2',
    refreshTokenCookie: isStaging ? 'refreshTokenStaging' : 'refreshToken',
  },
  api: {
    host: API_HOST,
    port: API_PORT,
    target: getTargetUrl(API_HOST, API_PORT, isProduction || isStaging),
    socketsTarget: getSocketsTargetUrl(API_HOST, API_PORT, isProduction),
    refreshTokenUrl: `${getTargetUrl(API_HOST, API_PORT)}/auth/refresh_token`,
    oauthCallbackUrl: `${getTargetUrl(API_HOST, API_PORT)}/auth/google_oauth2/callback`,
  },
  analytics: {
    mixpanelToken: '53d67578094e9ccb68e1c00716cf8fcd',
    amplitudeToken: 'd2372456a46c30a5bcc99db70c70f15b',
    facebookId: '450123895731847',
    googleTrackingId: 'UA-137163793-1',
    fullstoryOrgId: 'K6RAV',
    intercomAppId: 'npu8syb0',
  },
  googleAds: {
    trackingId: 'AW-704583719',
    conversionId: 'AW-704583719/7eOQCP7Xjq0BEKew_M8C',
  },
  oauth: {
    googleClientId: '630977891388-v7e74ah7e2s5f0irdmdciu8il3thm18h.apps.googleusercontent.com',
  },
  sentry: {
    dsn: isProduction ? 'https://1617d102065843df9e34bcf8550c75d7@sentry.io/1546971' : null,
  },
  featureFlags: {
    archive: true,
    placeholders: false,
  },
};

if (API_HTTP_AUTH_PASS && API_HTTP_AUTH_USER) {
  config.api.httpAuth = {
    user: API_HTTP_AUTH_USER,
    pass: API_HTTP_AUTH_PASS,
  };
}

module.exports = config;
