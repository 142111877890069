import React, { useState, useContext } from 'react';
import { KEY_ESCAPE } from 'keycode-js';
import { useBindActionToKey } from 'components/shared/BindActionToKey/BindActionToKey';

import { useDropdownContext } from './DropdownContext';

type ContentContextType = {
  replaceContent: (content: React.ReactNode) => void;
};

const contextValue: ContentContextType = {
  replaceContent: () => () => {},
};

const Context = React.createContext(contextValue);

const ContentContext: React.FC<{}> = ({ children }) => {
  const [content, setContent] = useState<React.ReactNode>(null);
  const { hide } = useDropdownContext();
  const replaceContent = (newContent: React.ReactNode) => setContent(newContent);

  useBindActionToKey(() => hide('Esc'), { keyCode: KEY_ESCAPE });

  return <Context.Provider value={{ replaceContent }}>{content || children}</Context.Provider>;
};

export const useContentContext = () => useContext(Context);

export default ContentContext;
