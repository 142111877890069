import React, { useEffect, useContext, useMemo } from 'react';
import FullStory from 'react-fullstory';

import { isClient } from 'helpers/dom';

import { env, analytics } from 'config/env';

import withCurrentUserTrackingProps from './withCurrentUserTrackingProps';
import { CurrentUserTrackingPropsQuery } from './__generated__/CurrentUserTrackingPropsQuery';

const noop = () => {};

/* Initialize Amplitube API */

const Amplitude = isClient && env.production ? require('amplitude-js') : null;

const amplitude = Amplitude
  ? Amplitude.getInstance()
  : {
      init: noop,
      logEvent: noop,
      setUserId: noop,
      setUserProperties: noop,
      regenerateDeviceId: noop,
    };

amplitude.init(analytics.amplitudeToken);

/* Initialize Mixpanel API */

const Mixpanel = isClient && env.production ? require('mixpanel-browser') : null;

const mixpanel = Mixpanel || {
  init: noop,
  track: noop,
  identify: noop,
  reset: noop,
  people: {
    set: noop,
  },
};

mixpanel.init(analytics.mixpanelToken, {
  debug: env.development,
});

/* Initialize Fullstory API */

const FullStoryAPI = env.production ? require('react-fullstory').FullStoryAPI : null;

export const fullstory = FullStoryAPI || noop;

interface TriggerFields {
  [key: string]: string | number;
}

interface TriggerOptions {
  [key: string]: string | number;
}

export type TriggerType = (event: string, fields?: TriggerFields, options?: TriggerOptions) => void;

const trackEvent: TriggerType = (event, fields = {}, options = {}): void => {
  if (isClient) {
    if (env.development) {
      /* eslint-disable no-console */
      console.log('\n\n👣 %cSTART TRACK EVENT\n', 'color: #45A163; text-decoration: underline;');
      console.log(event, fields, options);
      console.log('\n👣 %cEND TRACK EVENT\n\n', 'color: #ff0000; text-decoration: underline;');
      /* eslint-enable no-console */
    }

    mixpanel.track(event, fields);
    amplitude.logEvent(event, fields);
  }
};

export const identify = (user: CurrentUserTrackingPropsQuery): void => {
  const { currentUser, currentUserStats } = user;

  if (!currentUser) {
    return undefined;
  }

  mixpanel.identify(currentUser.id);
  mixpanel.people.set({
    $email: currentUser.email,
    $name: currentUser.name,
    ...currentUserStats,
  });

  amplitude.setUserId(currentUser.id);
  amplitude.setUserProperties({
    email: currentUser.email,
    name: currentUser.name,
    ...currentUserStats,
  });

  fullstory('identify', currentUser.id, {
    email: currentUser.email,
    name: currentUser.name,
    ...currentUserStats,
  });
  return undefined;
};

export const resetIdentity = (): void => {
  mixpanel.reset();
  amplitude.setUserId(null);
  amplitude.regenerateDeviceId();
  fullstory('identify', false);
};

/* TrackingProvider */

const context: { trigger: TriggerType } = {
  trigger: () => {},
};

const TrackingContext = React.createContext(context);

const Provider: React.FC<{
  children: React.ReactNode;
  userTrackingProps?: CurrentUserTrackingPropsQuery;
}> = ({ children, userTrackingProps }) => {
  useEffect(() => {
    if (userTrackingProps) {
      identify(userTrackingProps);
    }
  }, [userTrackingProps]);

  const providerValue = useMemo(
    () => ({
      trigger: trackEvent,
    }),
    [trackEvent],
  );

  return (
    <>
      {env.production && <FullStory org={analytics.fullstoryOrgId} />}
      <TrackingContext.Provider value={providerValue}>{children}</TrackingContext.Provider>
    </>
  );
};

export const TrackingProvider = withCurrentUserTrackingProps(Provider);

/* 
  Hook.

  1. track 
  const { track } = useTracking('form');

  track('submitBtn', 'click');
  => 'form_submitBtn_click'

  2. trackHandler
  const { trackHandler } = useTracking('form');

  <Button onClick={trackHandler(submitForm, 'submitBtn', 'click')} />
*/

export type TrackHookType = (
  subItem: string | number,
  event: string | number,
  fields?: TriggerOptions,
  options?: TriggerOptions,
) => void;

export const useTracking = (item = '') => {
  const { trigger }: { trigger: TriggerType } = useContext(TrackingContext);

  return useMemo(() => {
    const track: TrackHookType = (subItem, event, ...triggerArgs) => {
      const eventName = [item, subItem, event].filter(segment => segment).join('_');

      trigger(eventName, ...triggerArgs);
    };

    return {
      track,
      trackHandler: (
        handler: (...args: any[]) => void,
        ...triggerArgs: Parameters<TrackHookType>
      ) => (...handlerArgs: Parameters<any>) => {
        handler(...handlerArgs);
        track(...triggerArgs);
      },
    };
  }, [item]);
};

export interface TrackingProps {
  track: TrackHookType;
  trackHandler: (...args: any[]) => void; // todo
}

interface TrackProps {
  item?: string;
  children: (renderProps: TrackingProps) => React.ReactNode;
}

export const Track = ({ item = '', children }: TrackProps) => {
  const tracking = useTracking(item);

  return children(tracking);
};

// TODO type
// export const withTracking = (item = '') =>
//   hoistStatics(<P extends TrackingProps>(ComposedComponent: React.ComponentType<P>) => {
//     const WithTracking = (props: P) => {
//       const tracking = useTracking(item);
//
//       return <ComposedComponent {...props} {...tracking} />;
//     };
//
//     return WithTracking;
//   });

export const withTracking = (item = '') => <P extends TrackingProps>(
  ComposedComponent: React.ComponentType<P>,
) => {
  const WithTracking = (props: P) => {
    const tracking = useTracking(item);

    return <ComposedComponent {...props} {...tracking} />;
  };

  return WithTracking;
};
