import { gql } from '@apollo/client';
import { userFragment } from 'components/users/User/User';
import boardMembershipFragment from 'components/boards/memberships/boardMembershipFragment';
import projectMembershipFragment from 'components/workspaces/memberships/projectMembershipFragment';

const currentUserFragment = gql`
  fragment CurrentUserFragment on User {
    ...User
    emailConfirmed
    hasAutoGeneratedPassword
    providers
    personalProject {
      id
      slug
    }
    mailSettings {
      sendNewsletters
      sendNotificationLetters
    }
    notifications {
      unreadCount
    }
    boardInvites {
      id
    }
    boardMemberships {
      ...BoardMembershipFragment
    }
    projectMemberships {
      ...ProjectMembershipFragment
    }
  }
  ${userFragment}
  ${boardMembershipFragment}
  ${projectMembershipFragment}
`;

export default currentUserFragment;
