import { css, Interpolation, DefaultTheme, ThemedStyledProps } from 'styled-components';

export const mediaQuery = (value: number, direction = 'max-width') => <P extends object = {}>(
  strings: TemplateStringsArray,
  ...interpolations: Array<Interpolation<ThemedStyledProps<P, DefaultTheme>>>
) => css`
  @media (${direction}: ${value}px) {
    ${
      // @ts-ignore
      css(strings, ...interpolations)
    }
  }
`;

export const MOBILE_WIDTH = 600;

const screenSizes = {
  small: 1000,
  mobile: MOBILE_WIDTH,
} as const;

/**
 * Pre-defined media selectors:
 * ```
 * const Container = styled.div`
 *  ${mediaSelector.mobile} {
 *    flex-direction: row;
 *  }
 * `;
 * ```
 * TODO: maybe refine media queries to make them more precise.
 */
export const mediaSelector = {
  /**
   * Filters-out devices without hover support (read: mobile).
   */
  hoverSupported: '@media not all and (hover: none)',
  /**
   * Target touch devices: phones and tablets.
   */
  touchDevice: '@media (hover: none)',
  /**
   * Targets devices with window width 1000 and smaller.
   */
  small: `@media(max-width: ${screenSizes.small}px)`,
  /**
   * Targets mobile devices (screen width less thatn 600).
   */
  mobile: `@media(max-width: ${screenSizes.mobile}px)`,
} as const;

export const truncate = (width = '100%') => `
  max-width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const hideScrollbar = () => `
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar {
    display: none;
    background: transparent;
    visibility: hidden;
    width: 0px;
  }
`;

export const lineClamp = (lines = 2) => `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
`;
