import React, { useState, useCallback } from 'react';

type IsTappedHookType = {
  isTapped: boolean;
  tapHandleProps: {
    onTouchMove: (e: React.TouchEvent) => void;
    onTouchEnd: (e: React.TouchEvent) => void;
  };
  setIsTapped: React.Dispatch<React.SetStateAction<boolean>>;
};

const useIsTapped = (): IsTappedHookType => {
  const [isTapped, setIsTapped] = useState(false);
  const [isTapDisabled, setIsTapDisabled] = useState(false);

  const onTouchMove = useCallback(() => {
    setIsTapDisabled(true);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (!isTapDisabled) {
      setIsTapped(true);

      setTimeout(() => {
        setIsTapped(false);
        setIsTapDisabled(false);
      }, 200);
    }
  }, [isTapDisabled]);

  return {
    isTapped,
    setIsTapped,
    tapHandleProps: {
      onTouchMove,
      onTouchEnd,
    },
  };
};

export default useIsTapped;
