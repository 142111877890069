import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import Popup from 'client/components/v2/shared/Popup/Popup';
import { currentUserProjectsQuery, getIndexRedirectPath } from 'client/lib/withIndexRedirect';
import { CurrentUserProjectsQuery } from 'client/lib/__generated__/CurrentUserProjectsQuery';
import Loader from '../Loader/Loader';

const LoaderContainer = styled.div`
  min-height: 500px;
`;

type Props = {
  onClose?: (e?: KeyboardEvent | MouseEvent | React.MouseEvent<Element, MouseEvent>) => void;
  children: React.ReactNode;
};

const MasterDetail: React.FC<Props> = ({ onClose = () => {}, children }) => {
  const router = useRouter();
  const client = useApolloClient();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const isMasterPage = router.pathname === '/workspace' || router.pathname === '/user';

  const handleOnClose = async (
    e?: KeyboardEvent | MouseEvent | React.MouseEvent<Element, MouseEvent>,
  ) => {
    if (isRedirecting) {
      return null;
    }

    if (isMasterPage) {
      setIsRedirecting(true);

      try {
        const { data } = await client.query<CurrentUserProjectsQuery>({
          query: currentUserProjectsQuery,
        });
        const redirectPath = getIndexRedirectPath(data);

        return window.location.replace(redirectPath || '/');
      } catch (e) {
        setIsRedirecting(false);
      }
    }

    return onClose(e);
  };

  return (
    <Popup
      isOpen={true}
      draggableSheet={!isMasterPage}
      width={670}
      onClose={isRedirecting ? undefined : handleOnClose}
    >
      {isRedirecting && (
        <LoaderContainer>
          <Loader size={50} />
        </LoaderContainer>
      )}
      {!isRedirecting && typeof children === 'function' ? children() : children}
    </Popup>
  );
};

export default MasterDetail;
