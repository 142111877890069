import React, { useState, useEffect } from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { Body } from './styles';

export const Container = styled(Body)<{ styles?: FlattenSimpleInterpolation }>`
  position: relative;
  min-width: 254px;
  max-width: 320px;
  ${p => `${p.styles}`};
`;

const stopPropagation = (e: React.MouseEvent<Element>) => e.stopPropagation();

const DesktopContent: React.FC<{ styles?: FlattenSimpleInterpolation }> = ({
  styles,
  children,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    /**
     * It prevents flickering of menu items while using MousePositionDropdown.
     * */
    setTimeout(() => setMounted(true), 0);
  }, []);

  return (
    <Container
      style={{ pointerEvents: mounted ? 'auto' : 'none' }}
      styles={styles}
      onClick={stopPropagation}
      onContextMenu={stopPropagation}
      onDoubleClick={stopPropagation}
      onMouseDown={stopPropagation}
    >
      {children}
    </Container>
  );
};

export default DesktopContent;
