import React, { useState, useEffect } from 'react';
import copyToClipboard from 'helpers/copyToClipboard';

interface Props {
  value?: string;
  timeout?: number;
  onCopy?: () => void;
  children: (renderProps: {
    isCopied: boolean;
    handleCopy: (value?: string) => void;
    setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactElement<any>;
}

const CopyToClipboard: React.FC<Props> = ({
  value = '',
  onCopy = () => {},
  timeout = 3000,
  children,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), timeout);
    }
  }, [isCopied]);

  const handleCopy = (paramValue?: string): void => {
    const valueToCopy = (typeof paramValue === 'string' && paramValue) || value;

    if (!isCopied && valueToCopy) {
      if (copyToClipboard(valueToCopy)) {
        onCopy();
        setIsCopied(true);
      }
    }
  };

  return children({
    isCopied,
    handleCopy,
    setIsCopied,
  });
};

export default CopyToClipboard;
