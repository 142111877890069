import visibility, { State as VisibilityState } from './visibility/reducers';
import queue, { State as QueueState } from './queue/reducers';
import sidebarCollapse, { State as SidebarCollapseState } from './sidebarCollapse/reducers';
import placeholder, { State as PlaceholderState } from './placeholder/reducers';
import theme, { State as ThemeState } from './theme/reducers';

export type ReduxState = {
  visibility: VisibilityState;
  queue: QueueState;
  sidebarCollapse: SidebarCollapseState;
  placeholder: PlaceholderState;
  theme: ThemeState;
};

export default {
  visibility,
  queue,
  sidebarCollapse,
  placeholder,
  theme,
};
