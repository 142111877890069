import React, { Component } from 'react';
import styled from 'styled-components';
import { KEY_BACK_SPACE, KEY_RETURN } from 'keycode-js';

import { trim } from 'helpers/string';

import Input from 'components/Input/Input';
import { Tags, Tag } from 'components/shared';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 7px 10px;
  min-height: 48px;
  background-color: ${p => p.theme.currentTheme.inputBg};
  border-radius: 8px;
`;

const StyledInput = styled(Input)`
  height: 34px;
  margin-bottom: 4px;
  width: auto;
  flex: 1;
  padding: 0;
  box-shadow: none;
  padding-right: 6px;
  background-color: transparent;

  &:focus {
    box-shadow: none;
  }
`;

const Prefix = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-right: 6px;
`;

export interface TagType {
  id: number | string;
  label: string;
}

interface Props {
  tags: TagType[];
  inputProps: any; // fix me
  containerStyles?: {
    [key: string]: string;
  };
  prefix?: string;
  validate?: (value: string, tags: TagType[]) => boolean;
  renderInput?: (inputProps: any) => React.ReactNode;
  onAdd: (value: string) => void;
  onRemove: (tag: TagType, index: number) => void;
  onSubmit: (e: React.KeyboardEvent) => void;
}

class StatelessTagsInput extends Component<Props> {
  input: null | HTMLInputElement = null;

  static defaultProps = {
    onAdd: () => {},
    onSubmit: () => {},
  };

  componentDidMount() {
    setTimeout(() => this.focusInput(), 0);
  }

  private removeTag = (tag: TagType, index: number): void => {
    const { onRemove } = this.props;

    onRemove(tag, index);
    this.focusInput();
  };

  private onKeyDown = (e: React.KeyboardEvent): void => {
    const { tags, inputProps } = this.props;

    if (inputProps.onKeyDown) {
      inputProps.onKeyDown(e);
    }

    const value = trim(inputProps.value);
    const isEmptyAndDirty = !value && tags.length > 0;

    if (e.keyCode === KEY_BACK_SPACE && isEmptyAndDirty) {
      const index = tags.length - 1;

      return this.removeTag(tags[index], index);
    }

    if (e.keyCode === KEY_RETURN) {
      if (isEmptyAndDirty) {
        const { onSubmit } = this.props;

        return onSubmit(e);
      }

      const { validate } = this.props;
      const isValid = validate ? validate(value, tags) : true;

      if (value && isValid) {
        const { onAdd } = this.props;

        return onAdd(value);
      }
    }
    return undefined;
  };

  private setInput = (input: HTMLInputElement): void => {
    const { inputProps } = this.props;

    if (inputProps.ref) {
      inputProps.ref(input);
    }

    this.input = input;
  };

  private focusInput = (): void => {
    if (this.input) {
      this.input.focus();
    }
  };

  private renderInput() {
    const { tags, inputProps, renderInput } = this.props;

    const finalInputProps = {
      type: 'text',
      ...inputProps,
      placeholder: tags.length > 0 ? '' : inputProps.placeholder || 'Enter tag',
      ref: this.setInput,
      onKeyDown: this.onKeyDown,
    };

    if (renderInput) {
      return renderInput(finalInputProps);
    }

    return <StyledInput {...finalInputProps} />;
  }

  render() {
    const { tags, prefix, containerStyles } = this.props;

    return (
      <Container style={containerStyles}>
        <Tags>
          {prefix && <Prefix>{prefix}</Prefix>}
          {tags.map((tag, index) => (
            <Tag
              key={`tag-${tag.id}`}
              label={tag.label}
              onRemove={() => this.removeTag(tag, index)}
            />
          ))}
          {this.renderInput()}
        </Tags>
      </Container>
    );
  }
}

export default StatelessTagsInput;
