import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { useTracking } from 'lib/track';

import Userpic, { userpicFragment } from 'components/shared/Userpic/Userpic';
import { User as UserInterface } from './__generated__/User';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  padding: 60px 30px;
  overflow: auto;
`;

const UserpicContainer = styled.div`
  margin-bottom: 10px;
  display: inline-block;
`;

const Name = styled.h1`
  font-size: 2.8rem;
  line-height: 3.6rem;
  letter-spacing: -0.112rem;
`;

const Username = styled.div`
  font-size: 1.6rem;
  letter-spacing: -0.064rem;
  color: ${p => p.theme.currentTheme.notes};
`;

interface Props {
  user: UserInterface;
  canEdit: boolean;
}

const User: React.FC<Props> = ({ user, canEdit }) => {
  const { track } = useTracking('Profile');

  useEffect(
    () =>
      track('', 'Showed', {
        personal: canEdit ? 'Yes' : 'No',
      }),
    [],
  );

  return (
    <Container>
      <UserpicContainer>
        <Userpic image={user.profileImage} size={180} type="large" title={user.name} />
      </UserpicContainer>
      <Name>{user.name}</Name>
      <Username>{`@${user?.slug}`}</Username>
    </Container>
  );
};

export const userFragment = gql`
  fragment User on User {
    id
    name
    slug
    email
    profileImage {
      ...UserpicFragment
    }
  }
  ${userpicFragment}
`;

export default User;
