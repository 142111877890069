import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';

const StyledForm = styled.div`
  background: ${p => p.theme.currentTheme.popupContent};
  box-shadow: 0px 1px 2px ${p => p.theme.colors.transparentGray},
    0px 2px 6px ${p => p.theme.colors.transparentGray};
  border-radius: 10px;
  position: relative;
  z-index: 2;

  ${mediaSelector.mobile} {
    border-radius: 8px;
  }
`;

export default StyledForm;
