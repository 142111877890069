import React from 'react';
import styled from 'styled-components';

import { Control } from 'components/shared';
import Icon from './CloseButtonIcon.svg';

export const Container = styled(Control)`
  flex-shrink: 0;

  && {
    path {
      fill: ${p => p.theme.currentTheme.text};
    }
  }
`;

type Props = {
  size?: number;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const CloseButton: React.FC<Props> = ({ size = 28, onClick }) => (
  <Container
    style={{
      width: `${size}px`,
      height: `${size}px`,
    }}
    onClick={onClick}
  >
    <Icon width={size} height={size} />
  </Container>
);

export default CloseButton;
