import { MOBILE_WIDTH } from './style-utils';

// @ts-ignore FIXME
export const isClickedOutside = ({ target }, element) =>
  target !== element && !element.contains(target);

export const dispatchResizeEvent = () => window.dispatchEvent(new Event('resize'));

export const getPageOffset = () => ({
  x:
    window.pageXOffset !== undefined
      ? window.pageXOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollLeft,
  y:
    window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body).scrollTop,
});

export const isClient = typeof window !== 'undefined' && typeof window.document !== 'undefined';

export const isMobileScreen = () => isClient && window.innerWidth <= MOBILE_WIDTH;

export const isTouchDevice = isClient && 'ontouchstart' in document.documentElement;

export const isSafari = () =>
  isClient && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isPWA = () => isClient && window.matchMedia('(display-mode: standalone)').matches;

export const systemColorSchemeSupported = () =>
  isClient && window.matchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all';
