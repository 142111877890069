import React, { useState } from 'react';
import styled from 'styled-components';

import OnOutsideClick from 'components/OnOutsideClick/OnOutsideClick';

const Container = styled.div`
  height: 100%;
`;

type OnCloseEventType = KeyboardEvent | MouseEvent;

export type OpenOnClickRenderProps = {
  open: () => void;
  close: (e?: OnCloseEventType) => void;
};

interface Props {
  render: ((props: OpenOnClickRenderProps) => React.ReactNode) | React.ReactNode;
  renderOpened: ((props: OpenOnClickRenderProps) => React.ReactNode) | React.ReactNode;
  isCloseDisabled?: boolean;
  isOpenDisabled?: boolean;
  style?: React.CSSProperties | undefined;
  onOpen?: () => void;
  onClose?: (e?: OnCloseEventType) => void;
  containerProps?: (props: OpenOnClickRenderProps) => any;
}

const OpenOnClick: React.FC<Props> = props => {
  const {
    render,
    renderOpened,
    isCloseDisabled,
    isOpenDisabled,
    style,
    onOpen = () => {},
    onClose = () => {},
    containerProps = () => {},
  } = props;

  const [isOpened, setIsOpened] = useState(false);

  const renderProps = {
    open: () => {
      if (!isOpenDisabled) {
        setIsOpened(true);
        onOpen();
      }
    },
    close: (e?: OnCloseEventType) => {
      if (!isCloseDisabled) {
        setIsOpened(false);
        onClose(e);
      }
    },
  };

  if (isOpened) {
    return (
      <OnOutsideClick onClick={renderProps.close} bindToKey={27}>
        {typeof renderOpened === 'function' ? renderOpened(renderProps) : renderOpened}
      </OnOutsideClick>
    );
  }

  return (
    <Container
      style={style}
      onClick={typeof render === 'function' ? undefined : renderProps.open}
      {...containerProps(renderProps)}
    >
      {typeof render === 'function' ? render(renderProps) : render}
    </Container>
  );
};

export default OpenOnClick;
