import NextI18Next, { InitConfig, WithTranslation } from 'next-i18next';

import ICU from 'i18next-icu';
import en from 'i18next-icu/locale-data/en';
import ru from 'i18next-icu/locale-data/ru';
import es from 'i18next-icu/locale-data/es';
import fr from 'i18next-icu/locale-data/fr';
import pt from 'i18next-icu/locale-data/pt';

const icu = new ICU({
  localeData: [en, ru, es, fr, pt],
});

export const options = {
  defaultLanguage: 'en',
  otherLanguages: ['ru', 'es', 'fr', 'zh'],
  fallbackLng: 'en',
  load: 'languageOnly' as const,
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em', 'span'],
    wait: true,
    useSuspense: false,
  },
  use: [icu],
};

const NextI18NextInstance = new NextI18Next(options as InitConfig);

export const {
  appWithTranslation,
  i18n,
  withTranslation,
  useTranslation,
  Trans,
  config,
} = NextI18NextInstance;

export type WithTranslationProps = WithTranslation;
